import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { client } from '../apollo/client';
import { TOKEN_DATA } from '../apollo/queries';
dayjs.extend(utc);
const useTokenApi = (): number => {
  const [price, setPrice] = useState(0);

  useEffect(() => {
    fetch(new Request('https://intelligent-mahavira.xenwave.com/price/btn'))
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result) {
          const price = result;
          setPrice(price);
        }
      })
      .catch(() => null);
    const timer = setInterval(() => {
      fetch(new Request('https://intelligent-mahavira.xenwave.com/price/btn'))
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result) {
            const price = result;
            setPrice(price);
          }
        })
        .catch(() => null);
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  });

  return price;
};

const getTokenPrice = async (address: string, ethPrice: number) => {
  // initialize data arrays
  let priceUSD = 0;

  try {
    // fetch all current and historical data
    let result = await client.query({
      query: TOKEN_DATA(address),
      fetchPolicy: 'cache-first',
    });
    const data = result?.data?.tokens?.[0];

    // set data
    priceUSD = data?.derivedETH * ethPrice;
  } catch (e) {
    console.log(e);
  }
  return priceUSD;
};

const getTokenPrices = async (addresses: string[], ethPrice: number) => {
  // initialize data arrays
  return await Promise.all(
    addresses.map(async (address) => {
      let priceUSD = 0;

      try {
        // fetch all current and historical data
        let result = await client.query({
          query: TOKEN_DATA(address),
          fetchPolicy: 'cache-first',
        });
        const data = result?.data?.tokens?.[0];

        // set data
        priceUSD = data?.derivedETH * ethPrice;
      } catch (e) {
        console.log(e);
      }
      return { address, priceUSD };
    })
  );
};

export default useTokenApi;
export { getTokenPrice, getTokenPrices };
