import { Grid, InputBase } from '@material-ui/core';
import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import ETHJSONSpec from '@etclabscore/ethereum-json-rpc-specification/openrpc.json';
import { useTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';
import { createPreserveQueryHistory } from '../../helpers/createPreserveHistory';
import useEthRPCStore from '../../stores/useEthRPCStore';
import SearchBackground from '../../img/search-background.png';
import { SearchOutlined } from '@material-ui/icons';
import { name as networkName } from '../../constants';

const history = createPreserveQueryHistory(createBrowserHistory, [
  'network',
  'rpcUrl',
])();

function SearchBar() {
  const { t } = useTranslation();
  const [search, setSearch] = useState();
  const [ethRPC] = useEthRPCStore();

  const isAddress = (q: string): boolean => {
    const re = new RegExp(ETHJSONSpec.components.schemas.Address.pattern);
    return re.test(q);
  };

  const isKeccakHash = (q: string): boolean => {
    const re = new RegExp(ETHJSONSpec.components.schemas.Keccak.pattern);
    return re.test(q);
  };

  const isBlockNumber = (q: string): boolean => {
    const re = new RegExp(/^-{0,1}\d+$/);
    return re.test(q);
  };

  const handleSearch = async (qry: string | undefined) => {
    if (qry === undefined) {
      return;
    }
    const q = qry.trim();
    if (isAddress(q)) {
      history.push(`/address/${q}`);
    }
    if (isKeccakHash(q)) {
      let transaction;

      try {
        transaction = await ethRPC.eth_getTransactionByHash(q);
      } catch (e) {
        // do nothing
      }

      if (transaction) {
        history.push(`/tx/${q}`);
      }
      let block;
      try {
        block = await ethRPC.eth_getBlockByHash(q, false);
      } catch (e) {
        // do nothing
      }
      if (block) {
        history.push(`/block/${q}`);
      }
    }
    if (isBlockNumber(q)) {
      const block = await ethRPC.eth_getBlockByNumber(
        `0x${parseInt(q, 10).toString(16)}`,
        false
      );
      if (block) {
        history.push(`/block/${block.hash}`);
      }
    }
    window.location.reload();
  };

  return (
    <div>
      <div
        style={{
          minWidth: '100vw',
          minHeight: '265px',
          background: '#111',
          backgroundImage: `url(${SearchBackground})`,
          borderBottom: '1px solid rgba(255,255,255,0.05)',
          paddingTop: '55px',
          margin: 'auto',
          textAlign: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
            maxWidth: '1320px',
            margin: 'auto',
            textAlign: 'left',
          }}
        >
          <p
            style={{
              fontSize: '15pt',
              fontWeight: 'normal',
              color: '#FFF',
              margin: 'auto 10px 10px 10px',
            }}
          >
            {networkName} Block Explorer
          </p>
          <Grid item>
            <div>
              <InputBase
                placeholder={t('Search by Address / Txn Hash / Block / Token')}
                endAdornment={
                  <button
                    onClick={() => handleSearch(search)}
                    style={{
                      background: 'transparent',
                      border: '0px solid transparent',
                      cursor: 'pointer',
                    }}
                  >
                    <SearchOutlined
                      style={{
                        background: '#4183C4',
                        borderRadius: '10px',
                        padding: '5px',
                        color: '#FFF',
                        fontSize: '22pt',
                      }}
                    />
                  </button>
                }
                onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                  if (event.keyCode === 13) {
                    handleSearch(search);
                  }
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (event.target.value) {
                    const { value } = event.target;
                    setSearch(value as any);
                  }
                }}
                fullWidth
                style={{
                  background: '#FFF',
                  color: '#000',
                  borderRadius: '10px',
                  padding: '5px 5px 5px 15px',
                  margin: 'auto 10px auto 10px',
                  fontSize: '10pt',
                  fontWeight: 'normal',
                  width: '820px',
                  minWidth: '350px',
                  maxWidth: '85vw',
                  textAlign: 'center',
                }}
              />
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
}
export default SearchBar;
