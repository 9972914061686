import React from 'react';

/* Ad rendered on the top part of the AddressView Page */
export function AdAddressViewPageTop() {
  return (
    <div style={{ textAlign: 'center', margin: 'auto' }}>
      <div style={{ paddingTop: '12px', fontSize: '10pt' }}>
        <a
          href='https://xenwave.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          <b>
            <span role='img' aria-label='fire'>
              💬
            </span>{' '}
            Xenwave is also a Web 3 Social Media Platform where you can connect, share, and network.
          </b>
        </a>
      </div>
    </div>
  );
}

/* Ad rendered on the bottom part of the AddressView Page */
export function AdAddressViewPageBottom() {
  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ margin: '20px 15px -60px 15px', fontSize: '10pt' }}>
        <a
          href='https://discord.gg/tC4dkzJbHw'
          target='_blank'
          rel='noopener noreferrer'
        >
          <b>
            <span role='img' aria-label='fire'>
              🍻
            </span>{' '}
            Want to advertise here? Join us on Discord and reserve your spot today!
          </b>
        </a>
      </div>
    </div>
  );
}

/* Ad rendered on the general info part of the AddressView Page */
export function AdAddressViewPageEmbed() {
  return (
    <div>
      <div
        style={{
          fontSize: '10pt',
        }}
      >
        <a
          href='https://xenwave.com/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Xenwave is a decentralized exchange on Bitnet. Start trading today!
        </a>
      </div>
    </div>
  );
}
