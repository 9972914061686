import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import TxListBlock from '../TxListBlock';
import { hexToDate, hexToString, hexToNumber } from '@etclabscore/eserialize';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  LinearProgress,
  Grid,
  Button,
  Card,
  Tooltip,
  Box,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import CheckCircle from '@material-ui/icons/CheckCircle';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { useMetadata } from '../../hooks/useMetadata';
// import DoneIcon from '@material-ui/icons/Done';

const tableStyle = {
  borderBottom: '1px solid rgba(150,150,150,0.1)',
  fontSize: '9pt',
  padding: 10,
};
const tableStyleLast = {
  border: '0px',
  fontSize: '10pt',
};
const iconStyle = {
  fontSize: '10pt',
  margin: '0 3px 2px 0',
};

const iconStyleCopy = {
  fontSize: '10pt',
  margin: 'auto 3px -2px 3px',
  color: '#969696',
  cursor: 'pointer',
};

function BlockView(props: any) {
  const { block } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [copyState, setCopyState] = React.useState(false);

  const handleCopyHash = () => {
    if (block.hash) {
      navigator.clipboard.writeText(block.hash).then(() => {
        setCopyState(true);
      });
    }
  };

  const handleCopyParentHash = () => {
    if (parentHash) {
      navigator.clipboard.writeText(parentHash).then(() => {
        setCopyState(true);
      });
    }
  };

  const handleCopyFeeTaker = () => {
    if (miner) {
      navigator.clipboard.writeText(miner).then(() => {
        setCopyState(true);
      });
    }
  };

  const [, minerMetadata] = useMetadata(block?.miner);

  React.useEffect(() => {
    if (copyState) {
      setTimeout(() => {
        setCopyState(false);
      }, 1000);
    }
  }, [copyState]);

  if (!block) {
    return <div>Loading...</div>;
  }

  const {
    timestamp,
    parentHash,
    miner,
    nonce,
    difficulty,
    extraData,
    stateRoot,
    transactionsRoot,
    receiptsRoot,
    transactions,
    gasUsed,
    gasLimit,
    size,
  } = block;

  const filledPercent = (hexToNumber(gasUsed) / hexToNumber(gasLimit)) * 100;

  return (
    <div
      style={{
        margin: 'auto',
        marginTop: 0,
        maxWidth: '1320px',
      }}
    >
      <p
        style={{
          textAlign: 'left',
          margin: '20px 0px 20px 0px',
          fontSize: '15pt',
          fontWeight: 'normal',
        }}
      >
        Block{' '}
        <span style={{ color: '#969696', fontSize: '10pt' }}>
          #{hexToNumber(block.number)}
        </span>
      </p>

      <Grid container>
        <Grid item>
          <Button
            variant='outlined'
            style={{
              position: 'relative',
              background: '#4183C4',
              color: '#FFF',
              border: '0px',
              borderRadius: '5px',
              margin: '5px',
              textAlign: 'center',
            }}
          >
            Overview
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              history.push(`/block/${block.hash}/raw`);
            }}
            variant='outlined'
            style={{
              position: 'relative',
              color: '#4183C4',
              borderRadius: '5px',
              margin: '5px',
              textAlign: 'center',
            }}
          >
            Raw Data
          </Button>
        </Grid>
      </Grid>

      <Card
        style={{
          width: '1320px',
          maxWidth: '90vw',
          overflowX: 'auto',
          margin: '20px auto 20px auto',
          borderRadius: '10px',
          padding: '15px',
          boxShadow: '0px 0px 10px rgba(144, 144, 144, 0.02)',
        }}
      >
        <Grid container>
          <Grid item container style={tableStyle}>
            <Grid item md={2} justify='flex-start'>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                style={{ wordBreak: 'break-all' }}
                paddingRight={3}
              >
                <Tooltip title='The block height (or block number) indicates the length of the blockchain. It increases as new blocks are added to the ledger.'>
                  <InfoOutlined style={iconStyle} />
                </Tooltip>
                <span>Block Height:</span>
              </Box>
            </Grid>
            <Grid item md={10} justify='flex-start'>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                style={{ wordBreak: 'break-all' }}
              >
                <span>{hexToNumber(block.number)}</span>
              </Box>
            </Grid>
          </Grid>

          <Grid item container style={tableStyle}>
            <Grid item md={2} xs={12} justify='flex-start'>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                paddingRight={3}
                style={{ wordBreak: 'break-all' }}
              >
                <Tooltip title='The hash of the block header of the current block.'>
                  <InfoOutlined style={iconStyle} />
                </Tooltip>
                <span>Block Hash:</span>
              </Box>
            </Grid>
            <Grid item md={10} xs={12} justify='flex-start'>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                style={{ wordBreak: 'break-all' }}
              >
                <span>
                  {block.hash}
                  &nbsp;
                  {copyState ? (
                    <FileCopyIcon fontSize='small' style={iconStyleCopy} />
                  ) : (
                    <FileCopyIcon
                      fontSize='small'
                      style={iconStyleCopy}
                      onClick={() => handleCopyHash()}
                    />
                  )}
                </span>
              </Box>
            </Grid>
          </Grid>

          <Grid item container style={tableStyle}>
            <Grid item md={2} xs={12} justify='flex-start'>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                paddingRight={3}
                style={{ wordBreak: 'break-all' }}
              >
                <Tooltip title='The hash of the block head of the block added immediatelly before this one.'>
                  <InfoOutlined style={iconStyle} />
                </Tooltip>
                <span>Parent Hash:</span>
              </Box>
            </Grid>
            <Grid item md={10} xs={12} justify='flex-start'>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                style={{ wordBreak: 'break-all' }}
              >
                <RouterLink to={`/block/${parentHash}`}>
                  {parentHash}
                </RouterLink>
                &nbsp;
                <span>
                  {copyState ? (
                    <FileCopyIcon fontSize='small' style={iconStyleCopy} />
                  ) : (
                    <FileCopyIcon
                      fontSize='small'
                      style={iconStyleCopy}
                      onClick={() => handleCopyParentHash()}
                    />
                  )}
                </span>
              </Box>
            </Grid>
          </Grid>

          <Grid item container style={tableStyle}>
            <Grid item sm={2} justify='flex-start'>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                paddingRight={3}
                style={{ wordBreak: 'break-all' }}
              >
                <Tooltip title='Indicates if the block has been finalized.'>
                  <InfoOutlined style={iconStyle} />
                </Tooltip>
                <span>Status:</span>
              </Box>
            </Grid>
            <Grid item sm={10} justify='flex-start'>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                style={{ wordBreak: 'break-all' }}
              >
                <div
                  style={{
                    background: 'rgba(0, 161, 134, 0.1)',
                    color: 'rgb(0, 161, 134)',
                    border: '1px solid rgb(0, 161, 134)',
                    padding: '2px 5px',
                    borderRadius: 5,
                    maxWidth: 78,
                    fontSize: '8pt',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    lineHeight: 1,
                  }}
                >
                  <CheckCircle style={iconStyle} />
                  Finalized
                </div>
              </Box>
            </Grid>
          </Grid>

          <Grid item container style={tableStyle}>
            <Grid item md={2} xs={12} justify='flex-start'>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                paddingRight={3}
                style={{ wordBreak: 'break-all' }}
              >
                <Tooltip title='Date and time in which this block was produced.'>
                  <InfoOutlined style={iconStyle} />
                </Tooltip>
                Timestamp:
              </Box>
            </Grid>
            <Grid item md={10} xs={12} justify='flex-start'>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                style={{ wordBreak: 'break-all' }}
              >
                {t('Timestamp Date', { date: hexToDate(timestamp) })}
              </Box>
            </Grid>
          </Grid>

          <Grid item container style={tableStyle}>
            <Grid item md={2} xs={12} justify='flex-start'>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                paddingRight={3}
                style={{ wordBreak: 'break-all' }}
              >
                <Tooltip title='The wallet address recipient of the fees paid for the block to be processed, as well as any block rewards.'>
                  <InfoOutlined style={iconStyle} />
                </Tooltip>
                Fee Recipient:
              </Box>
            </Grid>
            <Grid item md={10} xs={12} justify='flex-start'>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                style={{ wordBreak: 'break-all' }}
              >
                <RouterLink to={`/address/${miner}`}>
                  {minerMetadata?.nameTag ?? miner}
                </RouterLink>
                &nbsp;<span>
                {copyState ? (
                  <FileCopyIcon fontSize='small' style={iconStyleCopy} />
                ) : (
                  <FileCopyIcon
                    fontSize='small'
                    style={iconStyleCopy}
                    onClick={() => handleCopyFeeTaker()}
                  />
                )}
                </span>
              </Box>
            </Grid>
          </Grid>

          <Grid item container style={tableStyle}>
            <Grid item md={2} xs={12} justify='flex-start'>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                paddingRight={3}
                style={{ wordBreak: 'break-all' }}
              >
                <Tooltip title='The difficulty of the network at the time this block was added to the ledger.'>
                  <InfoOutlined style={iconStyle} />
                </Tooltip>
                Block Difficulty:
              </Box>
            </Grid>
            <Grid item md={10} xs={12} justify='flex-start'>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                style={{ wordBreak: 'break-all' }}
              >
                {hexToNumber(difficulty)}
              </Box>
            </Grid>
          </Grid>

          <Grid item container style={tableStyle}>
            <Grid item md={2} xs={12} justify='flex-start'>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                paddingRight={3}
                style={{ wordBreak: 'break-all' }}
              >
                <Tooltip title='Block size in bytes.'>
                  <InfoOutlined style={iconStyle} />
                </Tooltip>
                Block Size:
              </Box>
            </Grid>
            <Grid item md={10} xs={12}>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                style={{ wordBreak: 'break-all' }}
              >
                {hexToNumber(size)} bytes
              </Box>
            </Grid>
          </Grid>

          <Grid item container style={tableStyle}>
            <Grid item md={2} xs={12} justify='flex-start'>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                paddingRight={3}
                style={{ wordBreak: 'break-all' }}
              >
                <Tooltip title='Indicates how much of the block capacity was used.'>
                  <InfoOutlined style={iconStyle} />
                </Tooltip>
                Gas Usage:
              </Box>
            </Grid>
            <Grid item md={10} xs={12}>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                height='100%'
                minHeight={40}
              >
                <div>
                  {hexToNumber(gasUsed)}{' '}
                  <span style={{ fontSize: '6pt', fontWeight: 'bold' }}>
                    {Number(gasUsed / gasLimit).toFixed(0)}% OF BLOCK CAPACITY
                  </span>
                </div>
                <LinearProgress
                  style={{ width: '150px' }}
                  value={filledPercent}
                  variant='determinate'
                />
              </Box>
            </Grid>
          </Grid>

          <Grid item container style={tableStyle}>
            <Grid item md={2} xs={12} justify='flex-start'>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                paddingRight={3}
                style={{ wordBreak: 'break-all' }}
              >
                <Tooltip title='Stamp used to demonstrate proof-of-work. This value should be 0 in most non-PoW networks.'>
                  <InfoOutlined style={iconStyle} />
                </Tooltip>
                Nonce:
              </Box>
            </Grid>
            <Grid item md={10} xs={12}>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                style={{ wordBreak: 'break-all' }}
              >
                {nonce}
              </Box>
            </Grid>
          </Grid>

          <Grid item container style={tableStyle}>
            <Grid style={tableStyleLast} md={2} xs={12} justify='flex-start'>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                paddingRight={3}
                style={{ wordBreak: 'break-all' }}
              >
                <Tooltip title='Additional data included in the block by its producer.'>
                  <InfoOutlined style={iconStyle} />
                </Tooltip>
                Extra Data:
              </Box>
            </Grid>
            <Grid style={tableStyleLast} md={10} xs={12}>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                style={{ wordBreak: 'break-all' }}
              >
                {hexToString(extraData)} ({extraData})
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <Card
        style={{
          width: '1320px',
          maxWidth: '90vw',
          overflowX: 'auto',
          margin: '20px auto 20px auto',
          borderRadius: '10px',
          padding: '15px',
          boxShadow: '0px 0px 10px rgba(144, 144, 144, 0.02)',
        }}
      >
        <Grid container>
          <Grid item container style={tableStyle}>
            <h3 style={{ textAlign: 'left' }}>More Information</h3>
          </Grid>

          <Grid item container style={tableStyle}>
            <Grid item md={2}>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                paddingRight={3}
                style={{ wordBreak: 'break-all' }}
              >
                <Tooltip title='The root of the state trie.'>
                  <InfoOutlined style={iconStyle} />
                </Tooltip>
                State Root:
              </Box>
            </Grid>
            <Grid md={10}>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                style={{ wordBreak: 'break-all' }}
              >
                {stateRoot}
              </Box>
            </Grid>
          </Grid>

          <Grid item container style={tableStyle}>
            <Grid item md={2}>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                paddingRight={3}
                style={{ wordBreak: 'break-all' }}
              >
                <Tooltip title='Block header transactional root hash.'>
                  <InfoOutlined style={iconStyle} />
                </Tooltip>
                Transactions Root:
              </Box>
            </Grid>
            <Grid item md={10}>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                style={{ wordBreak: 'break-all' }}
              >
                {transactionsRoot}
              </Box>
            </Grid>
          </Grid>

          <Grid item container style={tableStyle}>
            <Grid item container md={2}>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                paddingRight={3}
                style={{ wordBreak: 'break-all' }}
              >
                <Tooltip title='Block receipts root hash.'>
                  <InfoOutlined style={iconStyle} />
                </Tooltip>
                Receipts Root:
              </Box>
            </Grid>
            <Grid style={tableStyleLast} md={10}>
              <Box
                display='flex'
                alignItems='center'
                height='100%'
                minHeight={40}
                style={{ wordBreak: 'break-all' }}
              >
                {receiptsRoot}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <Card
        style={{
          width: '1320px',
          maxWidth: '90vw',
          overflowX: 'auto',
          margin: '20px auto 20px auto',
          borderRadius: '10px',
          padding: '15px',
          boxShadow: '0px 0px 10px rgba(144, 144, 144, 0.02)',
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <h3 style={{ textAlign: 'left' }}>Block Transactions</h3>
              </TableCell>
              <TableCell />
            </TableRow>

            <TableRow style={tableStyleLast}>
              <TxListBlock transactions={transactions} />
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}

export default BlockView;
