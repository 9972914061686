export const appVersion = process.env.REACT_APP_VERSION || '';
export const indexingStatus = process.env.REACT_APP_INDEXED || '';
export const indexUseApi = process.env.REACT_APP_USE_API || '';
export const indexApiEndpoint = process.env.REACT_APP_API_ENDPOINT || '';
export const indexApiToken = process.env.REACT_APP_API_TOKEN || '';
export const dbEndpoint = process.env.REACT_APP_DB_ENDPOINT || '';
export const dbUser = process.env.REACT_APP_DB_USER || '';
export const dbPass = process.env.REACT_APP_DB_PASSWORD || '';
export const useExternalJson = process.env.REACT_APP_ENABLE_EXTERNAL_JSON || '';
export const jsonCheckpoint = process.env.REACT_APP_LIBRARY_CHECKPOINT || '';
export const jsonContracts = process.env.REACT_APP_LIBRARY_CONTRACTS || '';
export const jsonTokens = process.env.REACT_APP_LIBRARY_TOKENS || '';
export const jsonWallets = process.env.REACT_APP_LIBRARY_WALLETS || '';
export const symbol = process.env.REACT_APP_NATIVE_SYMBOL || '';
export const nativeTokenName = process.env.REACT_APP_NATIVE_NAME || '';
export const viewBlockCount = process.env.REACT_APP_VIEW_BLOCK_INTERVAL || '';
export const gweiUnit = process.env.REACT_APP_GWEI_UNIT || '';
export const useTokenApi = process.env.REACT_APP_USE_TOKEN_API || '';
export const tokenApi = process.env.REACT_APP_TOKEN_API || '';
export const networkRpc = process.env.REACT_APP_RPC_URL || '';
export const name = process.env.REACT_APP_NETWORK_NAME || '';
export const networkType = process.env.REACT_APP_NETWORK_TYPE || '';
export const NetworkContextName = 'NETWORK';
