import React from 'react';
import { Card } from '@material-ui/core';
import logo from '../../img/logo-ico.png';

interface IProps {}

const styleCard = {
  width: '100%',
  maxWidth: '1320px',
  margin: '20px auto 20px auto',
  borderRadius: '10px',
  padding: '20px',
  boxShadow: '0px 0px 10px rgba(144, 144, 144, 0.02)',
};

const styleHeaderWrapper = {
  width: '100%',
  maxWidth: '100vw',
};

const styleHeaderSub = {
  width: '100vw',
  minHeight: '250px',
  background: 'rgba(150, 150, 150, 0.1)',
  padding: '20px',
  margin: 'auto',
};

const styleHeader = {
  width: '100%',
  maxWidth: '1320px',
  margin: '50px auto 50px auto',
  padding: '15px',
  alignItems: 'center',
};

const About: React.FC<IProps> = (props) => {
  return (
    <div style={styleHeaderWrapper}>
      <div style={styleHeaderSub}>
        <div style={styleHeader}>
          <img
            src={logo}
            height={32}
            style={{ marginBottom: '-20px' }}
            alt='logo'
          />
          <h2>
            <b>Navigator Explorer</b>
          </h2>
          <h3>Developed and Maintained by Restratagem</h3>
          <p>
            At Restratagem, we pride ourselves on being at the forefront of
            blockchain and Web 3 technology. Our team of seasoned full-stack
            developers has a passion for crafting robust, scalable solutions
            that meet the unique needs of our clients. With expertise spanning
            various platforms and technologies, we leverage cutting-edge tools
            to create seamless, secure, and efficient systems.{' '}
            <a
              href='https://restratagem.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              Visit our website
            </a>{' '}
            and get to know us better.
          </p>
        </div>
      </div>
      <div style={{ alignItems: 'center', margin: '100px auto auto auto' }}>
        <Card style={styleCard}>
          <h3>About BTNScan.com</h3>
          <p>
            We are a team of developers passionate about Bitnet, helping the
            network grow via the deployment and maintenance of infrastructure
            and applications for public and community use. Our applications are
            mostly free and we front up all the development and maintenance
            costs of the servers, domains, and hosts we use to make them
            accessible to you and everyone else using Bitnet - we do not get
            funding, grants, or any external financial help. So if you believe
            this is a noble cause and deserves some love{' '}
            <span role='img' aria-label='heart'>
              💝
            </span>{' '}
            please make us a donation by sending any amount of BTN to our
            donations wallet below.
          </p>
          <p>
            <b>Donations:&nbsp;</b>
            <a
              href='/address/0x205148B601d297740646fCd6C46dEE69b269681E'
              target='_self'
            >
              0x205148B601d297740646fCd6C46dEE69b269681E
            </a>
          </p>
        </Card>
      </div>
    </div>
  );
};

export default About;
