import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  LinearProgress,
} from "@material-ui/core"
import * as React from "react"
import { hexToNumber } from "@etclabscore/eserialize"
import { Link as RouterLink } from "react-router-dom"
import BlockLogo from "../../img/block-icon.png"
import { symbol } from "../../constants"
import { getMetadata } from '../../hooks/useMetadata'

const headStyle = {
  borderBottom: "1px solid rgba(150,150,150,0.1)",
  fontSize: "10pt",
}

function BlockListPage({ blocks }: any) {
  if (!blocks) {
    return null
  }
  const sortedBlocks = blocks.sort(
    (a: { number: number }, b: { number: number }) => {
      return b.number - a.number
    }
  )
  return (
    <div
      style={{
        overflowX: "auto",
        margin: "auto",
      }}
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={headStyle}></TableCell>
            <TableCell style={headStyle}>
              <b>Block</b>
            </TableCell>
            <TableCell style={headStyle}>
              <b>Processing Time</b>
            </TableCell>
            <TableCell style={headStyle}>
              <b>Transactions</b>
            </TableCell>
            <TableCell style={headStyle}>
              <b>Fee Recipient</b>
            </TableCell>
            <TableCell style={headStyle}>
              <b>Gas Used</b>
            </TableCell>
            <TableCell style={headStyle}>
              <b>Gas Limit</b>
            </TableCell>
            <TableCell style={headStyle}>
              <b>Fee</b>
            </TableCell>
          </TableRow>

          {sortedBlocks.map((b: any, index: number) => {
            // Shorten hash views by concatenating first and last 4 chars.
            const authorHashShort =
              b.miner.substring(0, 12) +
              "..." +
              b.miner.substring(b.miner.length - 11, b.miner.length - 0)

            const tableStyle = {
              borderLeft: `0px solid #${b.miner.substring(2, 8)}`,
              borderBottom: "1px solid rgba(150, 150, 150,0.1)",
              padding: "10px 10px 10px 18px",
            }

            // Tally transactions which create contracts vs transactions with addresses.
            const txTypes = {
              create: 0,
              transact: 0,
            }

            b.transactions.forEach((tx: any) => {
              if (tx.to !== null) {
                txTypes.transact++
              } else {
                txTypes.create++
              }
            })

            // Calculate difference of block timestamp from that of parent.
            let tdfp

            if (index === sortedBlocks.length - 1) {
              tdfp = 0
            } else {
              tdfp =
                hexToNumber(b.timestamp) -
                hexToNumber(sortedBlocks[index + 1].timestamp)
            }

            const filledPercent = Number(
              (hexToNumber(b.gasUsed) / hexToNumber(b.gasLimit)) * 100
            )

            return (
              <TableRow key={b.number} style={tableStyle}>
                <TableCell style={tableStyle}>
                  <img
                    alt="Block"
                    height="22"
                    src={BlockLogo}
                    style={{ margin: "auto" }}
                  />
                </TableCell>

                <TableCell component="th" scope="row" style={tableStyle}>
                  <RouterLink to={`/block/${b.hash}`}>
                    {parseInt(b.number, 16)}
                  </RouterLink>
                </TableCell>

                <TableCell style={tableStyle}>
                  <sub style={{ fontSize: "10pt", marginTop: "-5px" }}>
                    {tdfp > 0 ? `${tdfp}` : `${tdfp}`} Second(s)
                  </sub>
                </TableCell>

                <TableCell style={tableStyle}>
                  <RouterLink to={`/block/${b.hash}`}>
                    {txTypes.transact} txns
                  </RouterLink>
                </TableCell>

                <TableCell style={tableStyle}>
                  <p style={{ fontSize: "10pt" }}>
                    <RouterLink to={`/address/${b.miner}`}>
                    <b>{getMetadata(b.miner)?.nameTag ?? authorHashShort}</b>
                    </RouterLink>
                  </p>
                </TableCell>

                <TableCell style={tableStyle}>
                  {hexToNumber(b.gasUsed)}&nbsp;
                  <span style={{ fontSize: "6pt", fontWeight: "bold" }}>
                    {filledPercent.toFixed(0)}% OF BLOCK CAPACITY
                  </span>
                  <LinearProgress
                    style={{ width: "150px" }}
                    value={filledPercent}
                    variant="determinate"
                  />
                </TableCell>

                <TableCell style={tableStyle}>
                  {hexToNumber(b.gasLimit)}
                </TableCell>

                <TableCell style={tableStyle}>
                  {(Number(hexToNumber(b.gasUsed)) / 1000000000).toFixed(4)}{" "}
                  {symbol}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default BlockListPage
