import React from 'react'
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Card,
  Grid,
  Box,
} from '@material-ui/core'
import { hexToNumber } from '@etclabscore/eserialize'
import { Link as RouterLink } from 'react-router-dom'
import _ from 'lodash'
import { getMetadata } from '../hooks/useMetadata'
import { styled } from '@material-ui/core/styles'
import HashRate from '../components/HashRate'
import useEthRPCStore from '../stores/useEthRPCStore'
import { Block as IBlock } from '@etclabscore/ethereum-json-rpc'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import { useActiveWallets, useTotalTx, useTotalWallets } from '../hooks'

const config = {
  blockTime: 12, // seconds
  blockHistoryLength: 250,
  chartHeight: 140,
  chartWidth: 400,
}

const formatOptions = { maximumFractionDigits: 0 }

const styleBottomCard = {
  width: '100%',
  maxWidth: '1320px',
  margin: '10px auto 10px auto',
  border: '0px',
  borderRadius: '10px',
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '49.5%',
  boxShadow: '0px 0px 10px rgba(144, 144, 144, 0.02)',
}

const styleBottomContainer = {
  maxWidth: '1320px',
  margin: 'auto',
}

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress)

const ResponsiveBox1 = styled(Box)(() => ({
  '@media (min-width: 1320px)': {
    paddingRight: 10,
  },
}))

const ResponsiveBox2 = styled(Box)(() => ({
  '@media (min-width: 1320px)': {
    paddingLeft: 10,
  },
}))

const blockTopMiners = (blocks: any[]) => {
  const result = _(blocks)
    .chain()
    .countBy((b: any) => b.miner)
    .map((key: string, val: number) => ({
      address: val,
      blocksMined: key,
    }))
    .sortBy((item: any) => item.blocksMined)
    .reverse()
    .value()
  return result
}

interface IProps {
  blocks: any[]
  blockNumber: number
}

const MinerStatsTable: React.FC<IProps> = ({ blocks, blockNumber }) => {
  const topMiners = blockTopMiners(blocks)
  const [erpc] = useEthRPCStore()
  const [block, setBlock] = React.useState<IBlock>()
  const totalTx = useTotalTx()
  const totalWallets = useTotalWallets()
  const activeWallets = useActiveWallets()
  const lastDifficulty = block?.difficulty ?? ''

  React.useEffect(() => {
    if (!erpc || blockNumber === undefined) {
      return
    }
    erpc
      .eth_getBlockByNumber(`0x${blockNumber.toString(16)}`, true)
      .then((b) => {
        if (b === null) {
          return
        }
        setBlock(b)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [erpc, blockNumber])

  const txCount = React.useMemo(() => {
    if (blocks) {
      return blocks.reduce((count, block) => {
        if (block?.transactions?.length > 0)
          count += block?.transactions?.length
        return count
      }, 0)
    } else {
      return 0
    }
  }, [blocks])

  const networkUsage = Number((txCount / 11520 / 476) * 100)

  return (
    <div
      style={{
        margin: '0px',
        width: '1320px',
        maxWidth: '100%',
        padding: 5,
      }}
    >
      <Grid item container justify="center" style={styleBottomContainer}>
        <Grid item lg={6} sm={12}>
          <ResponsiveBox1>
            <Card style={styleBottomCard}>
              <div>
                <div
                  style={{
                    textAlign: 'left',
                    width: '100%',
                    maxWidth: '100vw',
                    padding: '10px',
                    borderBottom: '1px solid rgba(150, 150, 150, 0)',
                    margin: '10px',
                  }}
                >
                  <h4 style={{ marginBottom: '0px' }}>
                    <b>Mining Analytics (Last ≈ 4h)</b>
                  </h4>

                  <p>% / Miner</p>
                </div>
                <Grid
                  container
                  style={{
                    border: '0px solid',
                    borderRadius: '30px',
                    width: '100%',
                    maxWidth: '600px',
                    margin: 'auto',
                  }}
                >
                  {topMiners.map((minerData: any, index: number) => (
                    <Grid
                      container
                      key={minerData.address + '-' + index}
                      style={{
                        maxWidth: '100%',
                        overflow: 'auto',
                        padding: '10px',
                        borderBottom:
                          index === topMiners.length - 1
                            ? 'none'
                            : '1px solid rgba(150,150,150,0.1)',
                      }}
                    >
                      <Table style={{ width: '100%', padding: '10px' }}>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              style={{
                                fontSize: '10pt',
                                textAlign: 'start',
                                width: '20%',
                                borderBottom: 'none',
                              }}
                            >
                              {(minerData.blocksMined / 9.6).toFixed(2)}%
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: '0.8rem',
                                textAlign: 'start',
                                width: '80%',
                                borderBottom: 'none',
                              }}
                            >
                              <b>
                                ⛏{' '}
                                {getMetadata(minerData.address)?.nameTag ??
                                  'Unknown Miner'}
                              </b>
                              <RouterLink
                                to={`/address/${minerData.address}`}
                                style={{
                                  textDecoration: 'none',
                                  color: '#4183C4',
                                }}
                              >
                                <div>{minerData.address}</div>
                              </RouterLink>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Card>
          </ResponsiveBox1>
        </Grid>

        <Grid item lg={6} sm={12} style={{ maxWidth: '100%' }}>
          <ResponsiveBox2>
            <Card style={styleBottomCard}>
              <div>
                <div
                  style={{
                    textAlign: 'left',
                    width: '100%',
                    padding: '10px',
                    borderBottom: '1px solid rgba(150, 150, 150, 0.1)',
                    margin: '10px',
                  }}
                >
                  <h4 style={{ marginBottom: '0px' }}>
                    <b>Status & Performance Dashboard</b>
                  </h4>

                  <p>Based on approximations and on-chain data.</p>
                </div>

                <h3>
                  <HashRate block={block} blockTime={config.blockTime}>
                    {(hashRate: any) => (
                      <span>
                        {Intl.NumberFormat('en-US', formatOptions).format(
                          hashRate
                        )}{' '}
                        GHs
                      </span>
                    )}
                  </HashRate>
                </h3>
                <p style={{ marginTop: '-12px' }}>
                  Network Normalized Hashrate
                </p>

                {/* LINE DIVIDER */}
                <div
                  style={{
                    width: '100%',
                    borderTop: 'solid 1px rgba(150,150,150,0.1)',
                  }}
                />

                <h3>
                  {Number(hexToNumber(lastDifficulty) / 1000000000000).toFixed(
                    2
                  )}
                  T
                </h3>
                <p style={{ marginTop: '-12px' }}>Last Block Difficulty</p>

                {/* LINE DIVIDER */}
                <div
                  style={{
                    width: '100%',
                    borderTop: 'solid 1px rgba(150,150,150,0.1)',
                  }}
                />

                <h3>
                  {Intl.NumberFormat('en-US', formatOptions).format(txCount)}
                </h3>
                <p style={{ marginTop: '-12px' }}>
                  Transactions Processed (Last ≈ 4h)
                </p>

                {/* LINE DIVIDER */}
                <div
                  style={{
                    width: '100%',
                    borderTop: 'solid 1px rgba(150,150,150,0.1)',
                  }}
                />

                <h3>
                  {Intl.NumberFormat('en-US', formatOptions).format(totalTx)}
                </h3>
                <p style={{ marginTop: '-12px' }}>Total Network Transactions</p>

                {/* LINE DIVIDER */}
                <div
                  style={{
                    width: '100%',
                    borderTop: 'solid 1px rgba(150,150,150,0.1)',
                  }}
                />

                <h3>{(txCount / 11520).toFixed(3)}/tps</h3>
                <p style={{ marginTop: '-12px' }}>Live Throughput</p>

                {/* LINE DIVIDER */}
                <div
                  style={{
                    width: '100%',
                    borderTop: 'solid 1px rgba(150,150,150,0.1)',
                  }}
                />

                <h3>
                  {Intl.NumberFormat('en-US', formatOptions).format(
                    totalWallets
                  )}
                </h3>
                <p style={{ marginTop: '-12px' }}>Total Network Users</p>

                {/* LINE DIVIDER */}
                <div
                  style={{
                    width: '100%',
                    borderTop: 'solid 1px rgba(150,150,150,0.1)',
                  }}
                />

                <h3>
                  {Intl.NumberFormat('en-US', formatOptions).format(
                    activeWallets
                  )}
                </h3>
                <p style={{ marginTop: '-12px' }}>Active Network Users</p>

                {/* LINE DIVIDER */}
                <div
                  style={{
                    width: '100%',
                    borderTop: 'solid 1px rgba(150,150,150,0.1)',
                  }}
                />
                <h3>{networkUsage.toFixed(2)}%</h3>
                <BorderLinearProgress
                  variant="determinate"
                  value={networkUsage}
                />
                <p style={{ margin: '10px' }}>Estimated Live Network Usage</p>

                {/* LINE DIVIDER */}
                <div
                  style={{
                    width: '100%',
                    borderTop: 'solid 1px rgba(150,150,150,0.1)',
                  }}
                />

                <p
                  style={{
                    color: '#969696',
                    margin: '10px',
                    textAlign: 'left',
                  }}
                >
                  <b>* Disclosure: </b> some of this numbers and stats are based
                  on estimations and/or fomulas conceived by our own team. We
                  cannot guarantee these to be completely free of flaws and
                  erros, and we are not liable for the usage of the information
                  provided. Use it at your own risk.
                </p>
              </div>
            </Card>
          </ResponsiveBox2>
        </Grid>
      </Grid>
    </div>
  )
}

export default MinerStatsTable
