// import { useEffect } from 'react';
// import { uniqBy } from 'lodash';
import { name as networkName, networkType, networkRpc } from '../constants';
import { IChain as Chain } from '../models/chain';
import React from 'react';

// const mergeChainSets = (c1: Chain[], c2: Chain[]) => uniqBy(c1.concat(c2), 'name');

export default function () {
  const [chains, setChains] = React.useState<Chain[]>([
    {
      name: networkName,
      network: networkType,
      rpc: [networkRpc],
    },
  ]);

  return [chains, setChains];
}
