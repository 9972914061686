import { Box, Card } from '@material-ui/core';
import useEthRPCStore from '../stores/useEthRPCStore';
import * as React from 'react';
import getBlocks, { useBlockNumber } from '../helpers';
// import BlockPagination from '../components/BlockPagination';
import { History } from 'history';
import _ from 'lodash';
import { Block as IBlock } from '@etclabscore/ethereum-json-rpc';
import TokenListContainer from './TokenListContainer';
import LoadingImage from '../img/loading.gif';

const useState = React.useState;

interface IProps {
  match: {
    params: {
      address: string;
      block: string;
    };
  };
  history: History;
}

export default (props: IProps) => {
  const [erpc] = useEthRPCStore();
  const [blockNumber] = useBlockNumber(erpc);
  const [blocks, setBlocks] = useState<IBlock[]>();
  const { block } = props.match.params;
  const blockNum = block !== undefined ? parseInt(block, 10) : blockNumber;
  const from = Math.max(blockNum - 24, 0);
  const to = blockNum;

  React.useEffect(() => {
    if (blockNum === undefined || blockNumber === undefined) {
      return;
    }
    if (blockNum > blockNumber) {
      props.history.push(`/stats/miners/${blockNumber}`);
    }
    if (blockNum < 0) {
      props.history.push(`/stats/miners/0`);
    }
  }, [blockNumber, blockNum, props.history]);

  React.useEffect(() => {
    if (!erpc) {
      return;
    }

    getBlocks(from, to, erpc).then((bl) => {
      setBlocks(_.compact(bl));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to, erpc]);

  if (!blocks || blockNumber === undefined || blockNum > blockNumber) {
    return (
      <Box display='flex' justifyContent='center' height='75%'>
        <img alt='Logo' src={LoadingImage} />
      </Box>
    );
  }

  return (
    <div
      style={{
        margin: 'auto',
        marginTop: 0,
        maxWidth: '1320px',
        textAlign: 'center',
        border: '0px',
      }}
    >
      <p
        style={{
          textAlign: 'left',
          margin: '20px 0px 20px 0px',
          fontSize: '15pt',
          fontWeight: 'normal',
        }}
      >
        Bitnet Tokens
      </p>
      <Card
        style={{
          width: '1320px',
          maxWidth: '90vw',
          overflowX: 'auto',
          margin: '20px auto 20px auto',
          borderRadius: '10px',
          padding: '15px',
          boxShadow: '0px 0px 10px rgba(144, 144, 144, 0.02)',
        }}
      >
        {/*
        <BlockPagination
          from={from}
          to={to}
          disablePrev={blockNum >= blockNumber}
          disableNext={blockNum === 0}
          onPrev={() => {
            const newQuery = blockNum + 25;
            props.history.push(`/blocks/${newQuery}`);
          }}
          onNext={() => {
            const newQuery = Math.max(blockNum - 25, 0);
            props.history.push(`/blocks/${newQuery}`);
          }}
        />
        */}
        <TokenListContainer
          from={Math.max(blockNum - 24, 0)}
          to={blockNum}
          disablePrev={true}
          disableNext={blockNum < 25}
          onNext={() => {
            props.history.push(`/blocks/${blockNum - 25}`);
          }}
          style={{
            margin: 'auto',
            padding: '20px',
            width: '100%',
          }}
        />
      </Card>
    </div>
  );
};
