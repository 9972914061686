import { Box } from '@material-ui/core'
import useEthRPCStore from '../stores/useEthRPCStore'
import * as React from 'react'
import BlockView from '../components/BlockView'
import { Block as IBlock } from '@etclabscore/ethereum-json-rpc'
import { IsNumeric } from '../utils'
import LoadingImage from '../img/loading.gif'

export default function Block(props: any) {
  const {
    match: {
      params: { hash },
    },
  } = props
  const [block, setBlock] = React.useState<IBlock>()
  const [erpc] = useEthRPCStore()

  React.useEffect(() => {
    if (erpc === undefined) {
      return
    }

    // check hash is a valid number
    if (IsNumeric(hash)) {
      erpc
        .eth_getBlockByNumber('0x' + Number(hash).toString(16), true)
        .then((b) => {
          if (b === null) {
            return
          }
          setBlock(b)
        })
    } else {
      erpc.eth_getBlockByHash(hash, true).then((b) => {
        if (b === null) {
          return
        }
        setBlock(b)
      })
    }
  }, [hash, erpc])

  if (!block) {
    return <Box display="flex" justifyContent="center" height='100%'><img alt="Logo" src={LoadingImage} /></Box>
  }
  return <BlockView block={block} />
}
