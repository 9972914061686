import { Grid, Card, useTheme, Theme, Box, Tooltip } from '@material-ui/core';
import useEthRPCStore from '../stores/useEthRPCStore';
import * as React from 'react';
import { weiToGwei } from '../components/formatters';
import getBlocks, { useBlockNumber } from '../helpers';
import HashRate from '../components/HashRate';
import useInterval from 'use-interval';
import BlockListContainer from './BlockList';
import { hexToNumber } from '@etclabscore/eserialize';
import {
  Block as IBlock,
  IsSyncingResult as ISyncing,
} from '@etclabscore/ethereum-json-rpc';
import TokenLogo from '../img/token-logo.png';
import GasPriceLogo from '../img/gasprice-icon.png';
import LoadingImage from '../img/loading.gif';
import WidgetsIcon from '@material-ui/icons/Widgets';
import PublicIcon from '@material-ui/icons/Public';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import LockIcon from '@material-ui/icons/Lock';
import ShareIcon from '@material-ui/icons/Share';
import BigNumber from 'bignumber.js';
import { hashesToGH } from '../components/formatters';
import { VictoryChart, VictoryLine } from 'victory';
import getTheme from '../themes/dashTheme';
import {
  symbol,
  name as networkName,
  gweiUnit,
  // networkType,
} from '../constants';
import useTokenApi from '../hooks/useTokenApi';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { styled } from '@material-ui/core/styles';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { useTotalTx, useTotalWallets } from '../hooks';
import SearchBar from '../components/Search/SearchBar';
// import InfoIcon from '@material-ui/icons/Info'
// import TxListDashboard from './TxListDashboard';

const useState = React.useState;

const config = {
  blockTime: 12, // seconds
  blockHistoryLength: 250,
  chartHeight: 140,
  chartWidth: 400,
};

const formatOptions = { maximumFractionDigits: 0 };

// Stylers

const styleCard = {
  width: '100%',
  maxWidth: '1320px',
  margin: '10px auto 10px auto',
  borderRadius: '10px',
  padding: '40px',
  boxShadow: '0px 0px 10px rgba(144, 144, 144, 0.02)',
};

const styleImg = {
  margin: '2px 5px auto 0px',
};

const styleIcon = {
  margin: '4px',
};

const styleDashHeader = {
  fontSize: '8pt',
  marginBottom: 'auto',
};

const styleDashText = {
  fontSize: '10pt',
  marginBottom: 'auto',
};

const styleBottomContainer = {
  maxWidth: '1320px',
  margin: 'auto',
};

const styleBottomCard = {
  width: '100%',
  maxWidth: '1320px',
  margin: '10px auto 10px auto',
  border: '0px',
  borderRadius: '10px',
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '49.5%',
  boxShadow: '0px 0px 10px rgba(144, 144, 144, 0.02)',
};

const StyledVerticalSpacer = styled(Grid)(() => ({
  margin: '0px',
}));

const StyledGridItem = styled(Grid)(() => ({
  margin: 'auto 5px auto 5px',
  padding: 'auto 5px auto 5px',
  '@media (min-width: 1200px)': {
    maxWidth: 'calc(100%/6)',
  },
}));

const ResponsiveBox1 = styled(Box)(() => ({
  '@media (min-width: 1320px)': {
    paddingRight: 10,
  },
}));

const ResponsiveBox2 = styled(Box)(() => ({
  '@media (min-width: 1320px)': {
    paddingLeft: 10,
  },
}));

const blockMapTransactionCount = (block: any) => {
  return {
    x: hexToNumber(block.number),
    y: block.transactions.length,
  };
};

const blockMapHashRate = (block: any) => {
  return {
    x: hexToNumber(block.number),
    y: hashesToGH(
      new BigNumber(block.difficulty, 16).dividedBy(config.blockTime)
    ),
  };
};

export default (props: any) => {
  const [erpc] = useEthRPCStore();
  const theme = useTheme<Theme>();
  const victoryTheme = getTheme(theme);
  const [blockNumber] = useBlockNumber(erpc);
  const [chainId, setChainId] = useState<string>();
  const [block, setBlock] = useState<IBlock>();
  const [blocks, setBlocks] = useState<IBlock[]>();
  const [gasPrice, setGasPrice] = useState<string>();
  const [syncing, setSyncing] = useState<ISyncing>();
  const [peerCount, setPeerCount] = useState<string>();
  const txCount = useTotalTx();
  const totalWallets = useTotalWallets();
  const price = useTokenApi();

  React.useEffect(() => {
    if (!erpc) {
      return;
    }
    erpc.eth_chainId().then((cid) => {
      if (cid === null) {
        return;
      }
      setChainId(cid);
    });
  }, [erpc]);

  React.useEffect(() => {
    if (!erpc || blockNumber === undefined) {
      return;
    }
    erpc
      .eth_getBlockByNumber(`0x${blockNumber.toString(16)}`, true)
      .then((b) => {
        if (b === null) {
          return;
        }
        setBlock(b);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockNumber]);

  React.useEffect(() => {
    if (!erpc || blockNumber === null) {
      return;
    }
    getBlocks(
      Math.max(blockNumber - config.blockHistoryLength + 1, 0),
      blockNumber,
      erpc
    ).then((bl) => {
      setBlocks(bl);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [erpc, blockNumber]);

  useInterval(
    () => {
      if (!erpc) {
        return;
      }
      erpc.eth_syncing().then(setSyncing);
    },
    10000,
    true
  );

  React.useEffect(() => {
    if (!erpc) {
      return;
    }
    erpc.net_peerCount().then(setPeerCount);
  }, [erpc]);

  React.useEffect(() => {
    if (!erpc) {
      return;
    }
    erpc.eth_gasPrice().then(setGasPrice);
  }, [erpc]);

  if (
    blocks === undefined ||
    chainId === undefined ||
    gasPrice === undefined ||
    peerCount === undefined
  ) {
    return (
      <Box display='flex' justifyContent='center' height='75%'>
        <img alt='Logo' src={LoadingImage} />
      </Box>
    );
  }

  return (
    <div>
      <SearchBar />
      <div style={{ marginTop: '-100px', padding: 20 }}>
        <Card style={styleCard}>
          <Grid
            container
            justify='center'
            style={{ maxWidth: '100vw' }}
            spacing={5}
          >
            {/* NETWORK NAME 
            <StyledGridItem item md={3} sm={6} xs={12}>
              <Box display="flex">
                <LabelIcon fontSize="large" style={styleIcon} />
                <div>
                  <p style={styleDashHeader}>NETWORK NAME</p>
                  <p style={styleDashText}>{networkName}</p>
                </div>
              </Box>
            </StyledGridItem>
            */}

            {/* NETWORK TYPE 
            <StyledGridItem item md={3} sm={6} xs={12}>
              <Box display="flex">
                <InfoIcon fontSize="large" style={styleIcon} />
                <div>
                  <p style={styleDashHeader}>NETWORK TYPE</p>
                  <p style={styleDashText}>{networkType}</p>
                </div>
              </Box>
            </StyledGridItem>

            <StyledVerticalSpacer />
            */}

            {/* TOKEN PRICE */}
            <StyledGridItem item md={3} sm={6} xs={12}>
              <Box display='flex'>
                <img alt='Logo' height='28' src={TokenLogo} style={styleImg} />
                <div>
                  <p style={styleDashHeader}>{symbol} PRICE</p>
                  {/* ADD PRICE FETCHER */}
                  <p style={styleDashText}>${price.toFixed(3)}</p>
                </div>
              </Box>
            </StyledGridItem>

            <StyledVerticalSpacer />

            {/* WALLETS COUNT */}
            <StyledGridItem item md={3} sm={6} xs={12}>
              <Box display='flex'>
                <AccountBalanceWalletIcon fontSize='large' style={styleIcon} />
                <div>
                  <p style={styleDashHeader}>WALLETS</p>
                  <p style={styleDashText}>
                    {Intl.NumberFormat('en-US', formatOptions).format(
                      totalWallets
                    )}
                  </p>
                </div>
              </Box>
            </StyledGridItem>

            <StyledVerticalSpacer />

            {/* TX COUNT */}
            <StyledGridItem item md={3} sm={6} xs={12}>
              <Box display='flex'>
                <LocalAtmIcon fontSize='large' style={styleIcon} />
                <div>
                  <p style={styleDashHeader}>TOTAL TXNS</p>
                  <p style={styleDashText}>
                    {Intl.NumberFormat('en-US', formatOptions).format(txCount)}
                  </p>
                </div>
              </Box>
            </StyledGridItem>

            <StyledVerticalSpacer />

            {/* MARKET CAP */}
            <StyledGridItem item md={3} sm={6} xs={12}>
              <Box display='flex'>
                <MonetizationOnIcon fontSize='large' style={styleIcon} />
                <div>
                  <p style={styleDashHeader}>MARKET CAP</p>
                  <p style={styleDashText}>
                    $
                    {Intl.NumberFormat('en-US', formatOptions).format(
                      Number(blockNumber * 1.03) * price
                    )}
                  </p>
                </div>
              </Box>
            </StyledGridItem>

            <StyledVerticalSpacer />

            {/* CIRCULATING SUPPLY */}
            <StyledGridItem item md={3} sm={6} xs={12}>
              <Box display='flex'>
                <DataUsageIcon fontSize='large' style={styleIcon} />
                <div>
                  <p style={styleDashHeader}>
                    CURRENT SUPPLY
                    <span>
                      <Tooltip title='This is an approximation of the circulating supply taking into account uncle blocks and their rewards. For precision queries, please use GraphQL directly or another available method.'>
                        <InfoOutlined
                          style={{
                            fontSize: '10pt',
                            margin: 'auto 3px -2px 3px',
                          }}
                        />
                      </Tooltip>
                    </span>
                  </p>
                  <p style={styleDashText}>
                    ≈{' '}
                    {Intl.NumberFormat('en-US', formatOptions).format(
                      Number(blockNumber * 1.03)
                    )}{' '}
                    BTN
                  </p>
                </div>
              </Box>
            </StyledGridItem>

            <StyledVerticalSpacer />

            {/* BLOCK HEIGHT */}
            <StyledGridItem item md={3} sm={6} xs={12}>
              <Box display='flex'>
                <WidgetsIcon fontSize='large' style={styleIcon} />

                <div>
                  <p style={styleDashHeader}>BLOCK HEIGHT</p>
                  <p style={styleDashText}>
                    {Intl.NumberFormat('en-US', formatOptions).format(
                      blockNumber
                    )}
                  </p>
                </div>
              </Box>
            </StyledGridItem>

            {/* NETWORK ID */}
            <StyledGridItem item md={3} sm={6} xs={12}>
              <Box display='flex'>
                <PublicIcon fontSize='large' style={styleIcon} />

                <div>
                  <p style={styleDashHeader}>NETWORK ID</p>
                  <p style={styleDashText}>{hexToNumber(chainId)}</p>
                </div>
              </Box>
            </StyledGridItem>

            <StyledVerticalSpacer />

            {/* GAS PRICE */}
            <StyledGridItem item md={3} sm={6} xs={12}>
              <Box display='flex'>
                <LocalGasStationIcon fontSize='large' style={styleIcon} />
                <div>
                  <p style={styleDashHeader}>GAS PRICE</p>
                  <p style={styleDashText}>
                    {weiToGwei(hexToNumber(gasPrice)).toFixed(1)}
                    <span style={{ fontSize: '8pt', fontWeight: 'normal' }}>
                      &nbsp;{gweiUnit}
                    </span>
                  </p>
                </div>
              </Box>
            </StyledGridItem>

            <StyledVerticalSpacer />

            {/* HASHRATE */}
            <StyledGridItem item md={3} sm={6} xs={12}>
              <Box display='flex'>
                <LockIcon fontSize='large' style={styleIcon} />
                <div>
                  <p style={styleDashHeader}>HASH RATE</p>
                  <p style={styleDashText}>
                    <HashRate block={block} blockTime={config.blockTime}>
                      {(hashRate: any) => (
                        <span>
                          {Intl.NumberFormat('en-US', formatOptions).format(
                            hashRate
                          )}{' '}
                          GHs
                        </span>
                      )}
                    </HashRate>
                  </p>
                </div>
              </Box>
            </StyledGridItem>

            <StyledVerticalSpacer />

            {/* PEER COUNT */}
            <StyledGridItem item md={3} sm={6} xs={12}>
              <Box display='flex'>
                <ShareIcon fontSize='large' style={styleIcon} />
                <div>
                  <p style={styleDashHeader}>EXPLORER PEERS</p>
                  <p style={styleDashText}>
                    {hexToNumber(peerCount)}
                    <span style={{ fontSize: '8pt', fontWeight: 'normal' }}>
                      &nbsp;NODES
                    </span>
                  </p>
                </div>
              </Box>
            </StyledGridItem>

            {syncing && (
              <Grid container>
                <StyledVerticalSpacer />
                <Grid item>
                  <img
                    alt='Logo'
                    height='32'
                    src={GasPriceLogo}
                    style={styleImg}
                  />
                </Grid>
                <Grid item>
                  <p style={styleDashHeader}>Syncing</p>
                  {typeof syncing === 'object' && syncing.currentBlock && (
                    <p style={styleDashText}>
                      {hexToNumber(syncing.currentBlock)} /{' '}
                      {hexToNumber(syncing.highestBlock || '0x0')}
                    </p>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Card>

        <Grid item container justify='center' style={styleBottomContainer}>
          <Grid item lg={6} sm={12} style={{ maxWidth: '100%' }}>
            <ResponsiveBox1>
              <Card style={styleBottomCard}>
                <BlockListContainer
                  from={Math.max(blockNumber - 6, 0)}
                  to={blockNumber}
                  disablePrev={true}
                  disableNext={blockNumber < 6}
                  onNext={() => {
                    props.history.push(`/blocks/${blockNumber - 15}`);
                  }}
                  style={{
                    margin: 'auto',
                    padding: '20px',
                    width: '100%',
                  }}
                />
              </Card>
            </ResponsiveBox1>
          </Grid>

          <Grid item lg={6} sm={12} style={{ maxWidth: '100%' }}>
            <ResponsiveBox2>
              <Card style={styleBottomCard}>
                {/* ADD TXNS AFTER INDEXER IS INTEGRATED
            <TxListDashboard
              from={Math.max(blockNumber - 6, 0)}
              to={blockNumber}
              disablePrev={true}
              disableNext={blockNumber < 6}
              onNext={() => {
                props.history.push(`/blocks/${blockNumber - 15}`);
              }}
              style={{
                margin: 'auto',
                padding: '20px',
                width: '100%',
              }}
            /> */}

                <div style={{ padding: '20px', width: '100%', margin: 'auto' }}>
                  <h4>Network Hashrate Change</h4>
                  <p style={{ color: '#969696', marginTop: '-10px' }}>
                    For the latest 250 blocks
                  </p>
                  <VictoryChart
                    height={config.chartHeight}
                    width={config.chartWidth}
                    theme={victoryTheme as any}
                  >
                    <VictoryLine data={blocks.map(blockMapHashRate)} />
                  </VictoryChart>

                  {/* LINE DIVIDER */}
                  <div
                    style={{
                      width: '100%',
                      borderTop: 'solid 1px rgba(150,150,150,0.1)',
                    }}
                  />

                  <h4>Transactions</h4>
                  <p style={{ color: '#969696', marginTop: '-10px' }}>
                    In latest 250 blocks
                  </p>
                  <VictoryChart
                    height={config.chartHeight}
                    width={config.chartWidth}
                    theme={victoryTheme as any}
                  >
                    <VictoryLine data={blocks.map(blockMapTransactionCount)} />
                  </VictoryChart>

                  {/* LINE DIVIDER */}
                  <div
                    style={{
                      width: '100%',
                      borderTop: 'solid 1px rgba(150,150,150,0.1)',
                    }}
                  />

                  <p style={{ color: '#969696', marginTop: '-10px' }}>
                    <b>* Disclosure: </b> the {networkName} Explorer is an
                    unofficial explorer for the {networkName} network and
                    information displayed in this website might be innaccurate
                    or out of date. Use at your own risk.
                  </p>
                </div>
              </Card>
            </ResponsiveBox2>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
