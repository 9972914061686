import React from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ResponsivePopover from './ResponsivePopover';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  })
);

export default function MenuMore() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root} style={{ margin: '-4px auto auto auto' }}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={handleToggle}
          style={{ color: '#FFF' }}
        >
          More <KeyboardArrowDownIcon style={{ marginBottom: '5px' }} />
        </Button>

        <ResponsivePopover
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          handleClose={handleClose}
          handleListKeyDown={handleListKeyDown}
        >
          <MenuItem
            onClick={() => window.open('/about', '_parent')}
            style={{ fontSize: '10pt' }}
          >
            About Us
          </MenuItem>

          <MenuItem
            onClick={() => window.open('https://bridge.xenwave.com', '_blank')}
            style={{ fontSize: '10pt' }}
          >
            ReBridge ›
          </MenuItem>

          <MenuItem
            onClick={() => window.open('https://xenwave.com', '_blank')}
            style={{ fontSize: '10pt' }}
          >
            Xenwave Exchange ›
          </MenuItem>

          <MenuItem
            onClick={() => window.open('https://info.xenwave.com', '_blank')}
            style={{ fontSize: '10pt' }}
          >
            Xenwave Analytics ›
          </MenuItem>
        </ResponsivePopover>
      </div>
    </div>
  );
}
