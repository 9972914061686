import gql from 'graphql-tag';
export const BUNDLE_ID = '1';

export const GET_TX_BLOCK = gql`
  query transactions($timestampFrom: Int!, $timestampTo: Int!) {
    transactions(
      first: 1
      orderBy: timestamp
      orderDirection: asc
      where: { timestamp_gt: $timestampFrom }
    ) {
      id
      blockNumber
      timestamp
    }
  }
`;

const TokenFields = `
  fragment TokenFields on Token {
    id
    name
    symbol
    decimals
    derivedETH
    tradeVolume
    tradeVolumeUSD
    untrackedVolumeUSD
    totalLiquidity
    txCount
  }
`;

export const ETH_PRICE = (block) => {
  const queryString = block
    ? `
    query bundles {
      bundles(where: { id: ${BUNDLE_ID} } block: {number: ${block}}) {
        id
        ethPrice
      }
    }
  `
    : ` query bundles {
      bundles(where: { id: ${BUNDLE_ID} }) {
        id
        ethPrice
      }
    }
  `;
  return gql(queryString);
};

export const TOKEN_DATA = (tokenAddress, block) => {
  const queryString = `
    ${TokenFields}
    query tokens {
      tokens(${
        block ? `block : {number: ${block}}` : ``
      } where: {id:"${tokenAddress}"}) {
        ...TokenFields
      }
      pairs0: pairs(where: {token0: "${tokenAddress}"}, first: 50, orderBy: reserveUSD, orderDirection: desc){
        id
      }
      pairs1: pairs(where: {token1: "${tokenAddress}"}, first: 50, orderBy: reserveUSD, orderDirection: desc){
        id
      }
    }
  `;
  return gql(queryString);
};

export const TOKEN_TRANSFERS = (tx) => {
  const queryString = `
  query MyQuery {
    tokenTransfers(where: {tx: "${tx}"}) {
      id
      callMethod
      from {
        id
      }
      to {
        id
      }
      value
      token {
        id
        symbol
        decimals
      }
    }
  }`;
  return gql(queryString);
};

export const TOKEN_TRANSFERS_OF_ACCOUNT = (address) => {
  const queryString = `
  query MyQuery {
    tokenTransfers(
      orderBy: blockNumber, orderDirection: desc
      where:  
      { or : 
      [
        {
          from: "${address}"
      ,	},
        {
        	to: "${address}"
      	}
      ]
    }) {
      id
      callMethod
      from {
        id
      }
      to {
        id
      }
      value
      token {
        id
        symbol
        decimals
      }
      tx
      blockNumber
    }
  }`;
  return gql(queryString);
};

export const ACCOUNT_TOKENS = (address) => {
  const queryString = `
  query AccountQuery {
    account(id: "${address}") {
      balances {
        id
        token {
          id
        }
      }
    }
  }`;
  return gql(queryString);
};
