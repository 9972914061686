import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Grid,
  IconButton,
  Theme,
  Toolbar,
  Tooltip,
  styled,
} from '@material-ui/core';
import MenuBlockchain from '../Menu/Blockchain';
import MenuResources from '../Menu/Resources';
import MenuMore from '../Menu/More';
import useDarkMode from 'use-dark-mode';
import ExplorerLogo from '../../img/logo_base-img_white.png';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import { useTranslation } from 'react-i18next';
import HeaderSearchBar from '../Search/HeaderSearchBar';
import { useHistory } from 'react-router-dom';
import ResponsiveHeaderLinks from './ResponsiveHeaderLinks';

const LogoLink = styled('div')(({ theme }: { theme: Theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const Header = () => {
  const darkMode = useDarkMode();
  const { t } = useTranslation();
  const history = useHistory();
  const [isHomePage, setIsHomePage] = useState(
    history.location.pathname === '/'
  );

  useEffect(() => {
    const unlisten = history.listen((event) => {
      if (event.pathname !== '/') {
        setIsHomePage(false);
      } else {
        setIsHomePage(true);
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <AppBar
      position='sticky'
      color='inherit'
      elevation={0}
      style={{
        borderBottom: '1px solid rgba(255,255,255,0.05)',
        margin: 'auto',
        width: '100vw',
        maxWidth: '100vw',
        display: 'flex',
      }}
    >
      <Toolbar
        style={{
          margin: 'auto',
          marginTop: '5px',
          width: '1320px',
          maxWidth: '100vw',
          padding: 'auto 10px auto 10px',
          display: 'flex',
          userSelect: 'none',
          flexWrap: 'wrap',
        }}
      >
        {/* Wraps the entire header. */}

        <LogoLink>
          <a href='/'>
            <img alt='Logo' height='52' src={ExplorerLogo} />
          </a>
        </LogoLink>
        <HeaderSearchBar hidden={isHomePage} />
        {/* Menu */}
        <ResponsiveHeaderLinks>
          <div style={{ width: '100%', maxWidth: '20vh' }} />

          {/*
        <Grid item>
          <MenuTokens />
        </Grid>
        */}
          <Grid item>
            <MenuBlockchain />
          </Grid>
          <Grid item>
            <MenuResources />
          </Grid>
          <Grid item>
            <MenuMore />
          </Grid>

          <Grid item>
            <div>
              <Tooltip title={t('Dark/Light Mode') as string}>
                <IconButton
                  onClick={darkMode.toggle}
                  size='small'
                  style={{ color: '#FFF' }}
                >
                  {darkMode.value ? <WbSunnyIcon /> : <Brightness3Icon />}
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </ResponsiveHeaderLinks>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
