import { createReducer } from '@reduxjs/toolkit';
import { updateEthPrice } from './actions';

export interface XenwaveState {
  readonly etherPrice: number | undefined;
}

const initialState: XenwaveState = { etherPrice: 0 };

export default createReducer(initialState, (builder) =>
  builder.addCase(updateEthPrice, (state, action) => {
    const { etherPrice } = action.payload;
    state.etherPrice = etherPrice;
  })
);
