import { Theme } from '@material-ui/core'

const sansSerif = "'Roboto'"

const baseLabelStyles = {
  fontFamily: sansSerif,
  fontSize: 11,
  letterSpacing: 'normal',
  padding: 10,
  fill: 'transparent',
  stroke: 'transparent',
  strokeWidth: 1,
}

const centeredLabelStyles = {
  ...baseLabelStyles,
  textAnchor: 'middle',
}

const getTheme = (theme: Theme) => ({
  axis: {
    style: {
      axis: {
        stroke: 'transparent',
        strokeWidth: 1,
      },
      axisLabel: {
        ...centeredLabelStyles,
      },
      grid: {
        stroke: 'none',
      },
      ticks: {
        stroke: 'transparent',
      },
      tickLabels: {
        padding: 5,
        fill: 'transparent',
        strokeWidth: 1,
      },
    },
  },
  line: {
    style: {
      data: {
        stroke: theme.palette.primary.main,
        strokeWidth: 1,
      },
      labels: {
        ...baseLabelStyles,
      },
    },
  },
  bar: {
    style: {
      data: {
        fill: theme.palette.primary.main,
        stroke: theme.palette.primary.main,
      },
      labels: {
        ...baseLabelStyles,
      },
    },
  },
})

export default getTheme
