import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
// import { hexToNumber } from '@etclabscore/eserialize'
// import { symbol } from '../../constants'
import { getMetadata } from '../../hooks/useMetadata';
import { useBlockByBlockNumber, useBlockNumber } from '../../helpers';
import useEthRPCStore from '../../stores/useEthRPCStore';
import UnknownTokenIcon from '../../img/unknown-token-logo.png';

const formatOptions = { maximumFractionDigits: 4 };

const shortDateFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  timeZone: 'UTC',
  hour12: true, // Use 12-hour clock
};

const tableStyle = {
  borderBottom: '1px solid rgba(150,150,150,0.1)',
  fontSize: '10pt',
};

const headStyle = {
  borderBottom: '1px solid rgba(150,150,150,0.1)',
  fontSize: '10pt',
};

function getZerosBasedOnDecimals(tx: any) {
  // Get token decimals from the transaction object
  const tokenDecimals = tx?.token?.decimals;

  // Ensure tokenDecimals is a number
  const parsedTokenDecimals = parseInt(tokenDecimals || '', 10);

  // Handle invalid input
  if (isNaN(parsedTokenDecimals) || parsedTokenDecimals < 0) {
    return 0;
  }

  // Calculate the corresponding number of zeros
  return 10 ** parsedTokenDecimals;
}

function TxListItem({
  tx,
  showblockNumber,
  address,
}: {
  tx: any;
  showblockNumber?: boolean;
  address: string;
}) {
  const [erpc] = useEthRPCStore();
  const [blockNumber] = useBlockNumber(erpc);
  const block = useBlockByBlockNumber(Number(tx?.blockNumber), erpc);
  const latestBlock = useBlockByBlockNumber(blockNumber, erpc);

  const timestamp = React.useMemo(() => {
    if (block && block.timestamp) {
      return Number(block.timestamp) * 1000;
    } else {
      return null;
    }
  }, [block]);

  const deltaTime = React.useMemo(() => {
    if (!latestBlock || !timestamp) return null;

    const timeDifference = Number(latestBlock.timestamp) - timestamp / 1000;

    if (timeDifference < 60) {
      // Less than 1 minute
      return `${Math.floor(timeDifference)} seconds`;
    } else if (timeDifference < 3600) {
      // Less than 1 hour
      return `${Math.floor(timeDifference / 60)} minutes`;
    } else if (timeDifference < 86400) {
      // Less than 1 day
      return `${Math.floor(timeDifference / 3600)} hours`;
    } else {
      // 1 day or more
      return `${Math.floor(timeDifference / 86400)} days`;
    }
  }, [latestBlock, timestamp]);

  const shortDateFormat = React.useMemo(
    () =>
      timestamp
        ? new Date(Number(timestamp)).toLocaleDateString(
            'en-GB',
            shortDateFormatOptions
          )
        : null,
    [timestamp]
  );

  function TxIn() {
    return (
      <div
        style={{
          background: 'rgba(172, 224, 216, 0.1)',
          border: '1px solid #ACE0D8',
          padding: '5px',
          borderRadius: '5px',
          fontSize: '7pt',
          minWidth: '34px',
          maxWidth: '34px',
          textAlign: 'center',
          color: '#00A186',
        }}
      >
        <b>IN</b>
      </div>
    );
  }

  function TxOut() {
    return (
      <div
        style={{
          background: 'rgba(255, 233, 166, 0.1)',
          border: '1px solid #FFE9A6',
          padding: '5px',
          borderRadius: '5px',
          fontSize: '7pt',
          minWidth: '34px',
          maxWidth: '34px',
          textAlign: 'center',
          color: '#CF9A06',
        }}
      >
        <b>OUT</b>
      </div>
    );
  }

  function TxUnknown() {
    return (
      <div
        style={{
          background: 'rgba(65, 131, 196, 0.1)',
          color: '#4183C4',
          border: '1px solid #4183C4',
          padding: '5px',
          borderRadius: '5px',
          fontSize: '7pt',
          minWidth: '34px',
          maxWidth: '34px',
          textAlign: 'center',
        }}
      >
        <b>-</b>
      </div>
    );
  }

  return (
    <TableRow>
      <TableCell style={tableStyle}>
        <span
          style={{
            background: 'rgba(150,150,150,0.05)',
            color: '#4183C4',
            border: '1px solid #4183C4',
            padding: '5px',
            borderRadius: '5px',
            fontSize: '8pt',
          }}
        >
          <RouterLink to={`/tx/${tx?.tx}`}>{tx?.tx.substring(2, 8)}</RouterLink>
        </span>
      </TableCell>

      <TableCell style={tableStyle}>
        <span
          style={{
            background: 'rgba(150,150,150,0.05)',
            color: '#969696',
            border: '1px solid #969696',
            padding: '5px',
            borderRadius: '5px',
            fontSize: '8pt',
          }}
        >
          {tx.callMethod ? (
            <span>{tx.callMethod}</span>
          ) : `unknown` }
        </span>
      </TableCell>

      <TableCell style={tableStyle}>
        {shortDateFormat && deltaTime ? `${deltaTime}` : ''}
      </TableCell>

      <TableCell style={tableStyle}>
        <RouterLink to={`/address/${tx?.from?.id}`} target='_parent'>
          {getMetadata(tx?.from?.id)?.nameTag ??
            tx?.from?.id.substring(0, 10) +
              `...` +
              tx?.from?.id.substring(32, 42)}
        </RouterLink>
      </TableCell>

      <TableCell style={tableStyle}>
        {tx?.to?.id === address?.toLowerCase() ? (
          <TxIn />
        ) : tx?.to?.id !== null ? (
          <TxOut />
        ) : (
          <TxUnknown />
        )}
      </TableCell>

      <TableCell style={tableStyle}>
        {tx?.to?.id ? (
          <RouterLink to={`/address/${tx?.to?.id}`} target='_parent'>
            {getMetadata(tx.to?.id)?.nameTag ??
              tx?.to?.id?.substring(0, 10) +
                `...` +
                tx?.to?.id?.substring(32, 42)}
          </RouterLink>
        ) : null}
      </TableCell>

      <TableCell style={tableStyle}>
        {Intl.NumberFormat('en-US', formatOptions).format(
          Number(tx?.value / getZerosBasedOnDecimals(tx))
        )}
      </TableCell>

      <TableCell style={tableStyle}>
        <RouterLink to={`/address/${tx.token?.id}`} target='_parent'>
          <img
            src={
              getMetadata(tx?.token?.id)?.nameTag
                ? `../../metadata/img/${tx?.token?.id}.png`
                : UnknownTokenIcon
            }
            style={{ width: '16px', height: '16px', marginBottom: '-3px' }}
            alt=''
          />{' '}
          {getMetadata(tx?.token?.id)?.nameTag ??
            tx?.token?.id?.substring(0, 6) +
              `...` +
              tx?.token?.id?.substring(36, 42)}
        </RouterLink>{' '}
      </TableCell>
    </TableRow>
  );
}

export interface ITxListProps {
  transactions: any[];
  showBlockNumber?: boolean;
  address: string;
}

function TxListTokens(props: ITxListProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={headStyle}>Txn Hash</TableCell>
          <TableCell style={headStyle}>Method</TableCell>
          <TableCell style={headStyle}>Txn Age</TableCell>
          <TableCell style={headStyle}>From</TableCell>
          <TableCell style={headStyle}></TableCell>
          <TableCell style={headStyle}>To</TableCell>
          <TableCell style={headStyle}>Value</TableCell>
          <TableCell style={headStyle}>Token</TableCell>
          {/* <TableCell style={headStyle}>Index</TableCell>} */}
        </TableRow>
      </TableHead>

      <TableBody>
        {props.transactions.map((tx: any) => (
          <TxListItem
            key={tx?.id}
            tx={tx}
            showblockNumber={props.showBlockNumber}
            address={props.address}
          />
        ))}
      </TableBody>
    </Table>
  );
}

export default TxListTokens;
