import { useEffect, useMemo, useState } from 'react';
import ERC20_INTERFACE from '../constants/abis/erc20';
import Tokens from '../metadata/token-list.json';
import { useMultipleContractSingleData } from '../state/multicall/hooks';
import { useEthPrice } from '../state/xenwave/hooks';
import { getTokenPrices } from './useTokenApi';
import { ACCOUNT_TOKENS } from '../apollo/queries';
import { erc20Client } from '../apollo/client';
// const tokenAddresses = Object.keys(Tokens);

interface TokenInfoWithBalance {
  address: string;
  tokenLogo: string;
  tokenNameOverwrite: string;
  tokenSymbolOverwrite: string;
  totalSupplyOverwrite: string;
  totalSupplyBurn: string;
  description: string;
  links: { [link: string]: string };
  categoryTags: [string];
  verified: number;
  priceApi: string;
  tokenStandard: string;
  warning: string;
  balance: number;
}

const useTokensOfAccount = (account: string | undefined) => {
  const [tokens, setTokens] = useState<string[]>([]);

  useEffect(() => {
    if (account)
      erc20Client
        .query({
          query: ACCOUNT_TOKENS(account.toLowerCase()),
          fetchPolicy: 'no-cache',
        })
        .then((result: any) => {
          if (result?.data?.account?.balances) {
            const tokens: string[] =
              result?.data?.account?.balances?.map(
                ({ token: { id } }: { token: { id: string } }) => id
              ) ?? [];
            if (tokens?.length > 0) setTokens(tokens);
          }
        })
        .catch(() => {});
  }, [account]);

  return tokens;
};

const useTokenBalances = (
  account: string | undefined,
  tokenAddresses: string[]
) => {
  const balances = useMultipleContractSingleData(
    tokenAddresses,
    ERC20_INTERFACE,
    'balanceOf',
    [account]
  );

  const decimals = useMultipleContractSingleData(
    tokenAddresses,
    ERC20_INTERFACE,
    'decimals'
  );

  const names = useMultipleContractSingleData(
    tokenAddresses,
    ERC20_INTERFACE,
    'name',
    []
  );

  const symbols = useMultipleContractSingleData(
    tokenAddresses,
    ERC20_INTERFACE,
    'symbol',
    []
  );

  return useMemo(() => {
    return tokenAddresses.reduce<TokenInfoWithBalance[]>(
      (memo, tokenAddress, index) => {
        const balanceState = balances[index];
        const deciamlState = decimals[index];
        const symbolState = symbols[index];
        const nameState = names[index];
        if (
          !balanceState?.loading &&
          !deciamlState?.loading &&
          !symbolState?.loading &&
          !nameState?.loading
        ) {
          if (!balanceState?.error && !deciamlState?.error) {
            const _balance = Number(balanceState?.result?.[0] ?? 0);
            const _decimals = Number(deciamlState?.result?.[0] ?? 0);
            const _name = nameState?.result?.[0];
            const _symbol = symbolState?.result?.[0];
            if (_balance > 0) {
              const balance =
                _decimals > 0 ? _balance / Math.pow(10, _decimals) : _balance;
              const tokenInfo = (Tokens as any)[tokenAddress];
              const _data = {
                ...tokenInfo,
                address: tokenAddress,
                balance,
              };
              if (_name && _name !== '') _data.tokenNameOverwrite = _name;
              if (_symbol && _symbol !== '')
                _data.tokenSymbolOverwrite = _symbol;
              return [...memo, _data];
            }
          }
        }
        return memo;
      },
      []
    );
  }, [balances, decimals, symbols, names, tokenAddresses]);
};

const useTokenPrices = (tokenAddresses: string[]) => {
  const etherPrice = useEthPrice();
  const [tokenPrices, setTokenPrices] = useState<any[]>();

  useEffect(() => {
    if (etherPrice && tokenAddresses.length)
      getTokenPrices(tokenAddresses, etherPrice).then((prices) =>
        setTokenPrices(prices)
      );
  }, [etherPrice, tokenAddresses]);
  return tokenPrices;
};

export default useTokenBalances;
export { useTokenPrices, useTokensOfAccount };
