import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Wallets from '../../metadata/wallet-list.json';
import useTopWallets from '../../hooks/useTopWallets';
import { useBlockNumber } from '../../helpers';
import useEthRPCStore from '../../stores/useEthRPCStore';

const formatOptions = { maximumFractionDigits: 0 };

const headStyle = {
  borderBottom: '1px solid rgba(150,150,150,0.1)',
  fontSize: '10pt',
};

const tableStyle = {
  borderBottom: '1px solid rgba(150, 150, 150,0.1)',
  padding: '10px 10px 10px 18px',
};

interface WalletInfo {
  nameTag: string;
  description: string;
  links: {
    web: string;
    email: string;
  };
  categoryTags: string[];
  verified: number;
  warning: string;
}

interface WalletData {
  [walletId: string]: WalletInfo;
}

const walletData: WalletData = Wallets;

function TopWalletsPage(props: any) {
  const wallets = useTopWallets();
  const [erpc] = useEthRPCStore();
  const blockNumber = useBlockNumber(erpc);

  const maxSupply = React.useMemo(() => {
    if (blockNumber) return Number(blockNumber) * 1.03;
    else {
      return 0;
    }
  }, [blockNumber]);

  return (
    <div
      style={{
        overflowX: 'auto',
        margin: 'auto',
      }}
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={headStyle}>
              <b>#</b>
            </TableCell>
            <TableCell style={headStyle}>
              <b>Address</b>
            </TableCell>
            <TableCell style={headStyle}>
              <b>Name Tag</b>
            </TableCell>
            <TableCell style={headStyle}>
              <b>Balance</b>
            </TableCell>
            <TableCell style={headStyle}>
              <b>Percentage</b>
            </TableCell>
          </TableRow>

          {wallets.map(({ address, balance }, key) => {
            const walletInfo = walletData[address];

            return (
              <TableRow key={key} style={tableStyle}>
                <TableCell component='th' scope='row' style={tableStyle}>
                  <b>{key + 1}</b>
                </TableCell>

                <TableCell style={tableStyle}>
                  <RouterLink to={`/address/${address}`} target='_self'>
                    {address}
                  </RouterLink>
                </TableCell>

                <TableCell style={tableStyle}>
                  {walletInfo?.nameTag ?? ''}
                </TableCell>

                <TableCell style={tableStyle}>
                  <p style={{ fontSize: '10pt' }}>
                    {Intl.NumberFormat('en-US', formatOptions).format(
                      balance / Math.pow(10, 18)
                    )}{' '}
                    BTN
                  </p>
                </TableCell>

                <TableCell style={tableStyle}>
                  {maxSupply
                    ? ((balance / Math.pow(10, 18) / maxSupply) * 100).toFixed(
                        2
                      ) + '%'
                    : ''}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default TopWalletsPage;
