import { Grid, Tooltip, IconButton } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Public } from '@material-ui/icons';
import GitHubIcon from '@material-ui/icons/GitHub';
import { name as networkName } from '../constants';
import TelegramIcon from '@material-ui/icons/Telegram';
import { BiLogoDiscord } from 'react-icons/bi';

function Footer(props: any) {
  const { t } = useTranslation();

  const styleIcon = {
    fontSize: '16pt',
    color: '#fff',
    margin: '0px',
    padding: '0px',
  };

  return (
    <div
      style={{
        margin: 'auto',
        textAlign: 'center',
        marginTop: '85px',
        width: '100vw',
        minHeight: '260px',
        background: '#222',
        padding: '25px',
        borderTop: '1px solid rgba(255,255,255,0.05)'
      }}
    >
      <Grid container justify='center'>
        <Grid item>
          <div style={{ margin: '7px' }}>
            <Tooltip title={t('Website') as string}>
              <IconButton
                style={styleIcon}
                onClick={() => window.open('https://restratagem.com/')}
              >
                <Public />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>

        <Grid item>
          <div style={{ margin: '7px' }}>
            <Tooltip title={t('Discord') as string}>
              <IconButton
                style={styleIcon}
                onClick={() => window.open('https://discord.gg/n2ERW6HhWe')}
              >
                <BiLogoDiscord />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>

        <Grid item>
          <div style={{ margin: '7px' }}>
            <Tooltip title={t('Telegram') as string}>
              <IconButton
                style={styleIcon}
                onClick={() => window.open('https://t.me/restratagem')}
              >
                <TelegramIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>

        <Grid item>
          <div style={{ margin: '7px' }}>
            <Tooltip title={t('Github') as string}>
              <IconButton
                style={styleIcon}
                onClick={() => window.open('https://github.com/Restratagem')}
              >
                <GitHubIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      </Grid>

      <div
        style={{
          marginTop: '10px',
          marginBottom: '40px',
          textAlign: 'center',
          color: '#FFF',
        }}
      >
        <br />
        <p style={{ lineHeight: '.2' }}>
          <b>{networkName} Block Explorer</b>
        </p>
        <p>
          ©2023 ⚒ Proudly Built by{' '}
          <a
            href='https://restratagem.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <b>Restratagem</b>
          </a>
          . All rights reserved.
        </p>
        <p>
          <b>Donations: </b>{' '}
          <a href='/address/0x205148B601d297740646fCd6C46dEE69b269681E'>
            0x205148B601d297740646fCd6C46dEE69b269681E
          </a>
        </p>
        <p>
          <b>Want an explorer like this for your own EVM blockchain network?</b>
          <a
            href='https://restratagem.com/explorer'
            target='_blank'
            rel='noopener noreferrer'
          >
            {' '}
            Click here to find out how.
          </a>
        </p>
        <br />
      </div>
    </div>
  );
}
export default Footer;
