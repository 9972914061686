import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { hexToNumber } from '@etclabscore/eserialize';
import { symbol } from '../../constants';
import { getMetadata } from '../../hooks/useMetadata';

const tableStyle = {
  borderBottom: '1px solid rgba(150,150,150,0.1)',
  fontSize: '10pt',
};

const headStyle = {
  borderBottom: '1px solid rgba(150,150,150,0.1)',
  fontSize: '10pt',
};

function TxListItem({
  tx,
  showblockNumber,
}: {
  tx: any;
  showblockNumber?: boolean;
}) {
  return (
    <TableRow>
      <TableCell style={tableStyle}>
        <RouterLink to={`/tx/${tx.hash}`}>
          {tx.hash.substring(0, 15)}...
        </RouterLink>
      </TableCell>

      {showblockNumber && (
        <TableCell style={tableStyle}>
          <span
            style={{
              background: 'rgba(150,150,150,0.05)',
              color: '#4183C4',
              border: '1px solid #4183C4',
              padding: '5px',
              borderRadius: '5px',
              fontSize: '8pt',
            }}
          >
            <RouterLink to={`/block/${tx.blockHash}`} target='_parent'>
              {hexToNumber(tx.blockNumber)}
            </RouterLink>
          </span>
        </TableCell>
      )}

      <TableCell style={tableStyle}>
        <RouterLink to={`/address/${tx.from}`} target='_parent'>
          {getMetadata(tx.from)?.nameTag ?? tx.from}
        </RouterLink>
      </TableCell>

      <TableCell style={tableStyle}>
        {tx.to !== null ? (
          <RouterLink to={`/address/${tx.to}`} target='_parent'>
            {getMetadata(tx.to)?.nameTag ?? tx.to}
          </RouterLink>
        ) : null}
      </TableCell>

      <TableCell style={tableStyle}>
        {Number(tx.value / 1000000000000000000).toFixed(4)} {symbol}
      </TableCell>

      <TableCell style={tableStyle}>
        {Number(hexToNumber(tx.gas) / 1000000000).toFixed(6)} {symbol}
      </TableCell>

      <TableCell style={tableStyle}>
        {hexToNumber(tx.transactionIndex)}
      </TableCell>

    </TableRow>
  );
}

export interface ITxListProps {
  transactions: any[];
  showBlockNumber?: boolean;
}

function TxListBlock(props: ITxListProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={headStyle}>
            <b>Transaction Hash</b>
          </TableCell>
          {props.showBlockNumber && (
            <TableCell style={headStyle}>Block</TableCell>
          )}
          <TableCell style={headStyle}>From</TableCell>
          <TableCell style={headStyle}>To</TableCell>
          <TableCell style={headStyle}>Value</TableCell>
          <TableCell style={headStyle}>Fees</TableCell>
          <TableCell style={headStyle}>Index</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {props.transactions.map((tx: any) => (
          <TxListItem
            key={tx.hash}
            tx={tx}
            showblockNumber={props.showBlockNumber}
          />
        ))}
      </TableBody>
    </Table>
  );
}

export default TxListBlock;
