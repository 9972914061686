import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import Link from '@material-ui/core/Link';
import { hexToNumber } from '@etclabscore/eserialize';
import { Link as RouterLink } from 'react-router-dom';
import BlockLogo from '../../img/block-icon.png';
import { symbol } from '../../constants';
import { getMetadata } from '../../hooks/useMetadata';

const rightPaddingFix = {
  paddingRight: '24px',
  border: '0px',
};

function BlockList({ blocks }: any) {
  if (!blocks) {
    return null;
  }
  const sortedBlocks = blocks.sort(
    (a: { number: number }, b: { number: number }) => {
      return b.number - a.number;
    },
  );
  return (
    <div
      style={{
        overflowX: 'auto',
        margin: 'auto',
        padding: '5px',
      }}
    >
      <Table>
        <TableBody>
          {sortedBlocks.map((b: any, index: number) => {
            // Shorten hash views by concatenating first and last 4 chars.
            const authorHashShort =
              b.miner.substring(0, 6) +
              '...' +
              b.miner.substring(b.miner.length - 5, b.miner.length - 0);
            const name = getMetadata(b.miner)?.nameTag;

            // Colorize left border derived from author credit account.
            const authorHashStyle = {
              borderLeft: `1em solid #${b.miner.substring(2, 8)}`,
              border: '1px',
              borderBottom: '1px solid rgba(255,255,255,0.1)',
              width: '100%',
            };

            // Tally transactions which create contracts vs transactions with addresses.
            const txTypes = {
              create: 0,
              transact: 0,
            };

            b.transactions.forEach((tx: any) => {
              if (tx.to !== null) {
                txTypes.transact++;
              } else {
                txTypes.create++;
              }
            });

            // Calculate difference of block timestamp from that of parent.
            let tdfp;

            if (index === sortedBlocks.length - 1) {
              tdfp = 0;
            } else {
              tdfp =
                hexToNumber(b.timestamp) -
                hexToNumber(sortedBlocks[index + 1].timestamp);
            }

            return (
              <TableRow key={b.number} style={authorHashStyle}>
                <TableCell style={rightPaddingFix}>
                  <img
                    alt='Block'
                    height='22'
                    src={BlockLogo}
                    style={{ margin: 'auto' }}
                  />
                </TableCell>

                <TableCell component='th' scope='row' style={rightPaddingFix}>
                  <Link
                    component={({
                      className,
                      children,
                    }: {
                      children: any;
                      className: string;
                    }) => (
                      <RouterLink to={`/block/${b.hash}`}>
                        <p style={{ fontSize: '11pt' }}>{children}</p>
                      </RouterLink>
                    )}
                  >
                    {parseInt(b.number, 16)}
                  </Link>
                  <sub style={{ fontSize: '10pt', marginTop: '-5px' }}>
                    {tdfp > 0 ? `+${tdfp}` : `-${tdfp}`} secs
                  </sub>
                </TableCell>

                <TableCell style={rightPaddingFix}>
                  <Typography>
                    <p style={{ fontSize: '10pt' }}>
                      Fee Recipient
                      <Link
                        component={({
                          className,
                          children,
                        }: {
                          children: any;
                          className: string;
                        }) => (
                          <RouterLink to={`/address/${b.miner}`}>
                            {children}
                          </RouterLink>
                        )}
                      >
                        <span
                          style={{
                            margin: 'auto',
                            marginLeft: '5px',
                            fontSize: '10pt',
                            fontWeight: 'bold',
                          }}
                        >
                          {name ?? authorHashShort}
                        </span>
                      </Link>
                    </p>
                    <p style={{ fontSize: '10pt', marginTop: '-10px' }}>
                      {' '}
                      for{' '}
                      <RouterLink to={`/block/${b.hash}`}>
                        {txTypes.transact} txns
                      </RouterLink>{' '}
                      in {tdfp > 0 ? `${tdfp}` : `-${tdfp}`} secs
                    </p>
                  </Typography>
                </TableCell>

                <TableCell style={rightPaddingFix}>
                  <div
                    style={{
                      border: '1px solid #969696',
                      borderRadius: '10px',
                      margin: '10px',
                      padding: '5px',
                      fontSize: '8pt',
                      textAlign: 'center',
                    }}
                  >
                    {(Number(hexToNumber(b.gasUsed)) / 1000000000).toFixed(4)}{' '}
                    {symbol}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default BlockList;
