import React from "react"
import { useHistory } from "react-router-dom"
import { Button, Grid, Card } from "@material-ui/core"
import Editor from "@monaco-editor/react"
import useDarkMode from "use-dark-mode"

interface IProps {
  block: any
}

const BlockRaw: React.FC<IProps> = (props) => {
  const history = useHistory()
  const darkMode = useDarkMode()
  const { block } = props

  return (
    <div style={{ margin: "auto", maxWidth: "1320px", textAlign: "center" }}>
      <p
        style={{
          textAlign: "left",
          margin: "20px 0px 20px 0px",
          fontSize: "15pt",
          fontWeight: "normal",
        }}
      >
        Raw Data
      </p>

      <Grid container>
        <Grid item>
          <Button
            onClick={() => {
              history.push(`/block/${block.hash}`)
            }}
            variant="outlined"
            style={{
              position: "relative",
              color: "#4183C4",
              borderRadius: "5px",
              margin: "5px",
              textAlign: "center",
            }}
          >
            Block Summary
          </Button>
        </Grid>
      </Grid>

      <Card
        style={{
          width: "1320px",
          maxWidth: "90vw",
          overflowX: "auto",
          margin: "20px auto 20px auto",
          borderRadius: "10px",
          padding: "15px",
          boxShadow: "0px 0px 10px rgba(150,150,150,0.1)",
        }}
      >
        <h4>Block Data</h4>

        <Editor
          options={{
            minimap: {
              enabled: false,
            },
            wordWrap: "on",
            lineNumbers: "off",
            wrappingIndent: "deepIndent",
            readOnly: true,
            showFoldingControls: "always",
          }}
          theme={darkMode.value ? "dark" : "light"}
          width="100%"
          height="50vh"
          language="json"
          value={JSON.stringify(block, null, 4)}
        />
      </Card>
    </div>
  )
}

export default BlockRaw
