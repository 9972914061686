import ERPC, { Block } from "@etclabscore/ethereum-json-rpc"
import * as React from "react"
import useInterval from "use-interval"
import { hexToNumber } from "@etclabscore/eserialize"

export const getBlocks = (
  from: number,
  to: number,
  erpc: ERPC
): Promise<any> => {
  const promises: any[] = []

  for (let i = from; i <= to; i++) {
    promises.push(erpc.eth_getBlockByNumber(`0x${i.toString(16)}`, true))
  }
  return Promise.all(promises)
}

export const getBlock = (blockNumber: number, erpc: ERPC) => {
  return erpc.eth_getBlockByNumber(`0x${blockNumber.toString(16)}`, true)
}

export const useBlockNumber = (erpc: ERPC | undefined): [number] => {
  const [blockNumber, setBlockNumber] = React.useState<number>(NaN)
  useInterval(
    () => {
      if (!erpc) {
        return
      }
      erpc.eth_blockNumber().then((bn: string) => {
        setBlockNumber(hexToNumber(bn))
      })
    },
    7000,
    true
  )
  React.useEffect(() => {
    if (erpc) {
      erpc.eth_blockNumber().then((bn: string) => {
        setBlockNumber(hexToNumber(bn))
      })
    }
  }, [erpc])
  return [blockNumber]
}

export const useBlockByBlockNumber = (
  blockNumber: number,
  erpc: ERPC | undefined
): Block | undefined => {
  const [block, setBlock] = React.useState<Block>()

  React.useEffect(() => {
    if (!erpc) {
      return
    }
    if (!isNaN(blockNumber)) {
      getBlock(blockNumber, erpc).then((block: Block | null) => {
        if (block) {
          setBlock(block)
        }
      })
    }
  }, [blockNumber, erpc])

  return block
}

export function secondsToDhms(seconds: number) {
  seconds = Number(seconds)
  var d = Math.floor(seconds / (3600 * 24))
  var h = Math.floor((seconds % (3600 * 24)) / 3600)
  var m = Math.floor((seconds % 3600) / 60)
  var s = Math.floor(seconds % 60)

  var dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : ""
  var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : ""
  var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : ""
  var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : ""
  return dDisplay + hDisplay + mDisplay + sDisplay
}

export default getBlocks
