import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

export const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://graph.xenwave.com/subgraphs/name/restratagem/dex',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
});

export const erc20Client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://graph.xenwave.com/subgraphs/name/restratagem/erc20',
  }),
  cache: new InMemoryCache(),
});

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://graph.xenwave.com/subgraphs/name/restratagem/dex',
  }),
  cache: new InMemoryCache(),
});
