import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { InputBase, Theme, styled } from '@material-ui/core';
import ETHJSONSpec from '@etclabscore/ethereum-json-rpc-specification/openrpc.json';
import { useTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';
import { createPreserveQueryHistory } from '../../helpers/createPreserveHistory';
import useEthRPCStore from '../../stores/useEthRPCStore';
import { SearchOutlined } from '@material-ui/icons';

const history = createPreserveQueryHistory(createBrowserHistory, [
  'network',
  'rpcUrl',
])();

const HiddenDiv = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginBottom: 10,
  },
}));

const StyledInput = styled(InputBase)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    minWidth: 'inherit !important',
    maxWidth: 'inherit !important',
  },
}));

function HeaderSearchBar({ hidden }: { hidden: boolean }) {
  const { t } = useTranslation();
  const [search, setSearch] = useState();
  const [ethRPC] = useEthRPCStore();
  const isAddress = (q: string): boolean => {
    const re = new RegExp(ETHJSONSpec.components.schemas.Address.pattern);
    return re.test(q);
  };

  const isKeccakHash = (q: string): boolean => {
    const re = new RegExp(ETHJSONSpec.components.schemas.Keccak.pattern);
    return re.test(q);
  };

  const isBlockNumber = (q: string): boolean => {
    const re = new RegExp(/^-{0,1}\d+$/);
    return re.test(q);
  };

  const handleSearch = async (qry: string | undefined) => {
    if (qry === undefined) {
      return;
    }
    const q = qry.trim();
    if (isAddress(q)) {
      history.push(`/address/${q}`);
    }
    if (isKeccakHash(q)) {
      let transaction;

      try {
        transaction = await ethRPC.eth_getTransactionByHash(q);
      } catch (e) {
        // do nothing
      }

      if (transaction) {
        history.push(`/tx/${q}`);
      }
      let block;
      try {
        block = await ethRPC.eth_getBlockByHash(q, false);
      } catch (e) {
        // do nothing
      }
      if (block) {
        history.push(`/block/${q}`);
      }
    }
    if (isBlockNumber(q)) {
      const block = await ethRPC.eth_getBlockByNumber(
        `0x${parseInt(q, 10).toString(16)}`,
        false
      );
      if (block) {
        history.push(`/block/${block.hash}`);
      }
    }
    window.location.reload();
  };

  return (
    <HiddenDiv>
      {!hidden && (
        <StyledInput
          placeholder={t('Search by Address / Txn Hash / Block / Token')}
          endAdornment={
            <button
              onClick={() => handleSearch(search)}
              style={{
                background: 'transparent',
                border: '0px solid transparent',
                cursor: 'pointer',
              }}
            >
              <SearchOutlined
                style={{
                  background: '#4183C4',
                  borderRadius: '10px',
                  padding: '5px',
                  color: '#FFF',
                  fontSize: '18pt',
                }}
              />
            </button>
          }
          onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
            if (event.keyCode === 13) {
              handleSearch(search);
            }
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.value) {
              const { value } = event.target;
              setSearch(value as any);
            }
          }}
          style={{
            background: '#222',
            color: '#969696',
            border: '1px solid rgba(150, 150, 150, 0.2)',
            borderRadius: '10px',
            padding: '2px 2px 2px 12px',
            margin: 'auto 10px auto 10px',
            fontSize: '9pt',
            fontWeight: 'normal',
            minWidth: '350px',
            maxWidth: 500,
            textAlign: 'center',
            flex: 1,
          }}
        />
      )}
    </HiddenDiv>
  );
}
export default HeaderSearchBar;
