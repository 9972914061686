import * as React from 'react';
import {
  Typography,
  IconButton,
  Grid,
  Card,
  Box,
  Tabs,
  Tab,
  Paper,
} from '@material-ui/core';
import TxList from '../TxList';
import TxListTokens from '../TxListTokens';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useTransactions } from '../../hooks';
import { useTokenTransfersOfAccount } from '../../hooks/useTransfers';

export interface IProps {
  address: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: '10px',
    boxShadow: '0px 0px 10px rgba(144, 144, 144, 0.02)',
    width: '100%',
    '&:hover': {},
    '&$selected': {},
    '&:focus': {},
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
});

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    // color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    height: '30px',
    border: '1px solid transparent',
    minHeight: '100%',
    minWidth: '0px',
    overflow: 'visible',
    borderRadius: 10,
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  selected: {
    color: 'white',
    backgroundColor: '#222',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    padding: '0px',
    '& *:first-child': {
      margin: '5px',
    },
    '& *:last-child': {
      margin: '5px',
    },
  },
}))((props: any) => {
  let newProps = { ...props };
  // console.log(props.unRead)
  return <Tab {...newProps} />;
});

const StyledTabs = withStyles({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'visible',
  },
  scroller: {
    height: '100%',
  },
  flexContainer: {
    height: '100%',
  },
  indicator: {
    display: 'none',
  },
})((props: any) => <Tabs {...props} TabIndicatorProps={{}} />);

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const txPerPage = 15;

const AddressTransactions: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const [paginationNum, setPagination] = React.useState(0);
  const [tokenPaginationNum, setTokenPagination] = React.useState(0);

  const { transactions } = useTransactions(props.address);
  const tokenTransfers = useTokenTransfersOfAccount(
    props.address?.toLowerCase()
  );

  const disabledPrev = React.useMemo(
    () => paginationNum === 0,
    [paginationNum]
  );
  const disabledNext = React.useMemo(() => {
    if (transactions?.length) {
      if (transactions.length > (paginationNum + 1) * txPerPage) {
        return false;
      } else return true;
    }
    return true;
  }, [paginationNum, transactions]);

  const txListWithPagination = React.useMemo(() => {
    return transactions?.slice(
      paginationNum * txPerPage,
      (paginationNum + 1) * txPerPage
    );
  }, [transactions, paginationNum]);

  const onPrev = () => {
    if (!disabledPrev) setPagination(paginationNum - 1);
  };
  const onNext = () => {
    if (!disabledNext) setPagination(paginationNum + 1);
  };

  const transfersWithPagination = React.useMemo(() => {
    return tokenTransfers?.slice(
      tokenPaginationNum * txPerPage,
      (tokenPaginationNum + 1) * txPerPage
    );
  }, [tokenTransfers, tokenPaginationNum]);

  const tokenDisabledPrev = React.useMemo(
    () => tokenPaginationNum === 0,
    [tokenPaginationNum]
  );
  const tokenDisabledNext = React.useMemo(() => {
    if (tokenTransfers?.length) {
      if (tokenTransfers.length > (tokenPaginationNum + 1) * txPerPage) {
        return false;
      } else return true;
    }
    return true;
  }, [tokenPaginationNum, tokenTransfers]);

  const onPrevToken = () => {
    if (!tokenDisabledPrev) setTokenPagination(tokenPaginationNum - 1);
  };
  const onNextToken = () => {
    if (!tokenDisabledNext) setTokenPagination(tokenPaginationNum + 1);
  };
  // const handleChangeIndex = (index: number) => {
  //   setValue(index);
  // };

  return (
    <Grid container style={{ padding: 10, width: '100%' }}>
      <Paper className={classes.root}>
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab label='Transactions' {...a11yProps(0)} />
          <StyledTab label='Token Transactions' {...a11yProps(1)} />
        </StyledTabs>
      </Paper>
      <Card
        style={{
          width: '100%',
          maxWidth: '1320px',
          margin: '13px auto auto auto',
          borderRadius: '10px',
          padding: '25px',
          boxShadow: '0px 0px 10px rgba(144, 144, 144, 0.02)',
          textAlign: 'left',
        }}
      >
        <TabPanel value={value} index={0}>
          <Box width='100%' overflow='auto'>
            <TxList
              transactions={txListWithPagination || []}
              showBlockNumber={true}
              address={props.address}
            ></TxList>
          </Box>
          {(!txListWithPagination || txListWithPagination.length === 2) && (
            <Grid container style={{ padding: '25px', fontSize: '10pt' }}>
              <p
                style={{
                  fontSize: '7pt',
                  color: '#969696',
                  textAlign: 'center',
                  margin: 'auto',
                }}
              >
                NO MORE TXNS TO SHOW
              </p>
            </Grid>
          )}
          <Grid
            container
            justify='center'
            style={{ margin: '20px auto -20px auto', width: '100%' }}
          >
            <IconButton onClick={onPrev} disabled={disabledPrev}>
              <ArrowBackIos />
            </IconButton>
            <IconButton onClick={onNext} disabled={disabledNext}>
              <ArrowForwardIos />
            </IconButton>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Box width='100%' overflow='auto'>
            <TxListTokens
              transactions={transfersWithPagination || []}
              showBlockNumber={true}
              address={props.address}
            ></TxListTokens>
          </Box>
          {(!transfersWithPagination ||
            transfersWithPagination.length === 2) && (
            <Grid
              container
              style={{ padding: '25px', fontSize: '10pt', textAlign: 'center' }}
            >
              <p
                style={{
                  fontSize: '7pt',
                  color: '#969696',
                  textAlign: 'center',
                  margin: 'auto',
                }}
              >
                NO MORE TXNS TO SHOW
              </p>
            </Grid>
          )}
          <Grid
            container
            justify='center'
            style={{ margin: '20px auto -20px auto', width: '100%' }}
          >
            <IconButton onClick={onPrevToken} disabled={tokenDisabledPrev}>
              <ArrowBackIos />
            </IconButton>
            <IconButton onClick={onNextToken} disabled={tokenDisabledNext}>
              <ArrowForwardIos />
            </IconButton>
          </Grid>
        </TabPanel>
      </Card>
    </Grid>
  );
};

export default AddressTransactions;
