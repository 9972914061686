import { Box } from '@material-ui/core'
import useEthRPCStore from '../stores/useEthRPCStore'
import * as React from 'react'
import BlockList from '../components/BlockList'
import getBlocks from '../helpers'
import { Block as IBlock } from '@etclabscore/ethereum-json-rpc'
import LoadingImage from '../img/loading.gif'

interface IProps {
  from: number
  to: number
  disablePrev: boolean
  disableNext: boolean
  style?: any
  onNext?: any
  onPrev?: any
}

export default function BlockListContainer(props: IProps) {
  const { from, to, style } = props
  const [erpc] = useEthRPCStore()
  const [blocks, setBlocks] = React.useState<IBlock[]>()
  React.useEffect(() => {
    if (!erpc) {
      return
    }
    getBlocks(from, to, erpc).then(setBlocks)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to])

  if (!blocks) {
    return <Box display="flex" justifyContent="center" height='100%'><img alt="Logo" src={LoadingImage} /></Box>
  }
  return (
    <div style={style}>
      <div style={{ width: '100%' }}>
        <h4>Latest Blocks</h4>
        {/* <Grid container justify='flex-end'>
        <IconButton onClick={props.onPrev} disabled={props.disablePrev}>
          <ArrowBackIos />
        </IconButton>
        <IconButton onClick={props.onNext} disabled={props.disableNext}>
          <ArrowForwardIos />
        </IconButton>
      </Grid> */}
        <BlockList blocks={blocks} />
      </div>
    </div>
  )
}
