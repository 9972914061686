import { useEffect, useState } from 'react';

interface Wallet {
  address: string;
  balance: number;
}

const useTopWallets = (): Wallet[] => {
  const [topWallets, setTopWallets] = useState<Wallet[]>([]);

  useEffect(() => {
    const fetchTopWallets = async () => {
      try {
        const wallets = await fetch(
          new Request('https://intelligent-mahavira.xenwave.com/wallets/top')
        )
          .then((response) => {
            return response.json();
          })
          .catch(() => null);
        if (wallets) {
          const topWallets = wallets?.filter((wallet: any) => {
            if (wallet && wallet.balance && wallet.address) {
              return true;
            } else {
              return false;
            }
          });
          setTopWallets(topWallets);
        }
      } catch (error) {
        console.error('Error fetching top wallets:', error);
      }
    };

    fetchTopWallets();
  }, []);

  return topWallets;
};

export default useTopWallets;
