import { Box } from '@material-ui/core';
import * as React from 'react';
import AddressView from '../components/AddressView';
import { useBlockNumber } from '../helpers';
import useEthRPCStore from '../stores/useEthRPCStore';
import { hexToNumber } from '@etclabscore/eserialize';
import AddressTransactions from '../components/AddressTransactions';
import { History } from 'history';
import { AdAddressViewPageBottom } from '../ads/Ads';
import LoadingImage from '../img/loading.gif';

const unit = require('ethjs-unit'); //tslint:disable-line

interface IProps {
  match: {
    params: {
      address: string;
      block: string;
    };
  };
  history: History;
}

const Address: React.FC<IProps> = ({ match, history }) => {
  const { address, block } = match.params;
  const [erpc] = useEthRPCStore();
  const [blockNumber] = useBlockNumber(erpc);
  const [transactionCount, setTransactionCount] = React.useState<string>();
  const [balance, setBalance] = React.useState<string>();
  const [code, setCode] = React.useState<string>();
  const blockNum = block === undefined ? blockNumber : parseInt(block, 10);

  React.useEffect(() => {
    if (isNaN(blockNum) || isNaN(blockNumber)) {
      return;
    }
    if (blockNum > blockNumber) {
      history.push(`/address/${address}/${blockNumber}`);
    }
    if (blockNum < 0) {
      history.push(`/address/${address}/0`);
    }
  }, [blockNumber, blockNum, history, address]);

  React.useEffect(() => {
    if (blockNumber === undefined || !erpc) {
      return;
    }
    const hexBlockNumber = `0x${blockNumber.toString(16)}`;
    erpc
      .eth_getTransactionCount(address, hexBlockNumber)
      .then((txCount) => {
        if (txCount === null) {
          return;
        }
        setTransactionCount(txCount);
        return txCount;
      })
      .then((txCountRes: string | undefined) => {
        if (txCountRes) {
          erpc.eth_getBalance(address, hexBlockNumber).then((b) => {
            if (b === null) {
              return;
            }
            setBalance(b);
          });
          erpc.eth_getCode(address, hexBlockNumber).then((c) => {
            if (c === null) {
              return;
            }
            setCode(c);
          });
        }
      });
  }, [blockNumber, address, erpc]);

  if (
    transactionCount === undefined ||
    balance === undefined ||
    code === undefined
  ) {
    return (
      <Box display='flex' justifyContent='center' height='75%'>
        <img alt='Logo' src={LoadingImage} />
      </Box>
    );
  }
  return (
    <div
      style={{ margin: 'auto', maxWidth: '1320px', width: '100%', padding: 10 }}
    >
      <AddressView
        address={address}
        txCount={transactionCount ? hexToNumber(transactionCount) : 0}
        balance={unit.fromWei(balance || 0, 'ether')}
        code={code}
      />
      <AddressTransactions address={address} />
      <AdAddressViewPageBottom />
    </div>
  );
};

export default Address;
