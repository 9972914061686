import { CssBaseline, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import React, { Dispatch, useState, useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import useDarkMode from 'use-dark-mode';
import './App.css';
import Address from './containers/Address';
import Block from './containers/Block';
import Dashboard from './containers/Dashboard';
import Transaction from './containers/Transaction';
import { darkTheme, lightTheme } from './themes/jadeTheme';
import useInterval from 'use-interval';
import { createBrowserHistory } from 'history';
import {
  StringParam,
  QueryParamProvider,
  useQueryParams,
} from 'use-query-params';
import { createPreserveQueryHistory } from './helpers/createPreserveHistory';
import BlockRawContainer from './containers/BlockRawContainer';
// import RPCPage from './components/RPCPage';
import About from './components/About';
import Api from './components/Api';
import TransactionRawContainer from './containers/TransactionRawContainer';
import MinerStatsPage from './containers/MinerStatsPage';
import BlocksPage from './containers/BlockListPage';
import TokensPage from './containers/TokenListPage';
import TopWalletsPage from './containers/TopWalletsPage';
import { IChain as Chain } from './models/chain';
import useChainListStore from './stores/useChainListStore';
import useEthRPCStore from './stores/useEthRPCStore';
import Web3ReactManager from './components/Web3ReactManager';
import Header from './components/Header';
// import MenuServices from './components/Menu/Services';
// import MenuTokens from './components/Menu/Tokens';

const history = createPreserveQueryHistory(createBrowserHistory, [
  'network',
  'rpcUrl',
])();

declare const window: any;

function App(props: any) {
  const darkMode = useDarkMode();

  const theme = darkMode.value ? darkTheme : lightTheme;

  const [selectedChain, setSelectedChain] = useState<Chain>();
  const [chains] = useChainListStore<[Chain[], Dispatch<Chain[]>]>();
  const [ethRPC, setEthRPCChain] = useEthRPCStore();

  const [query, setQuery] = useQueryParams({
    network: StringParam,
    rpcUrl: StringParam,
  });

  useEffect(() => {
    if (selectedChain !== undefined) {
      return;
    }
    if (chains === undefined) {
      return;
    }
    if (chains.length === 0) {
      return;
    }
    if (query.rpcUrl) {
      return;
    }

    setSelectedChain(chains[0]);
  }, [query, chains, selectedChain]);

  useEffect(() => {
    if (!chains || chains.length === 0) {
      return;
    }
    if (query.rpcUrl) {
      return;
    }

    if (query.network && selectedChain !== undefined) {
      if (query.network === selectedChain.name) {
        return;
      }
    }

    if (chains && query.network) {
      const foundChain = chains.find(
        (chain: Chain) => chain.name === query.network
      );
      setSelectedChain(foundChain);
    } else {
      setSelectedChain(chains[0]);
    }
  }, [chains, selectedChain, query]);

  useEffect(() => {
    if (selectedChain === undefined) {
      return;
    }
    const { name } = selectedChain as Chain;

    if (name !== query.network) {
      setQuery({ network: name });
      history.push({
        pathname: history.location.pathname,
        search: `?network=${name}`,
      });
    }
  }, [selectedChain, query, setQuery]);

  useEffect(() => {
    if (selectedChain !== undefined) {
      setEthRPCChain(selectedChain);
    }
  }, [selectedChain, setEthRPCChain]);

  React.useEffect(() => {
    if (ethRPC) {
      ethRPC.startBatch();
    }
  }, [ethRPC]);

  useInterval(
    () => {
      if (ethRPC) {
        ethRPC.stopBatch();
        ethRPC.startBatch();
      }
    },
    100,
    true
  );

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <Header />
        <Box display='flex' flexDirection='column' maxWidth='100vw' flexGrow={1}>
          {/* ROUTER */}
          <QueryParamProvider ReactRouterRoute={Route}>
            <CssBaseline />
            <Web3ReactManager>
              <Switch>
                <Route path={'/'} component={Dashboard} exact={true} />
                <Route
                  path={'/stats'}
                  component={MinerStatsPage}
                  exact={true}
                />
                <Route path={'/blocks'} component={BlocksPage} exact={true} />
                <Route
                  path={'/blocks/:block'}
                  component={BlocksPage}
                  exact={true}
                />
                <Route path={'/stats/:block'} component={MinerStatsPage} />
                <Route
                  path={'/block/:hash/raw'}
                  component={BlockRawContainer}
                />
                <Route path={'/block/:hash'} component={Block} />
                <Route
                  path={'/tx/:hash/raw'}
                  component={TransactionRawContainer}
                />
                <Route path={'/tx/:hash'} component={Transaction} />
                <Route path={'/address/:address/:block'} component={Address} />
                <Route path={'/address/:address'} component={Address} />
                <Route path={'/contract/:address'} component={Address} />
                <Route path={'/token/:address'} component={Address} />
                <Route path={'/about'} component={About} />
                <Route path={'/tokens'} component={TokensPage} />
                <Route path={'/accounts'} component={TopWalletsPage} />
                <Route path={'/api'} component={Api} />
              </Switch>
            </Web3ReactManager>
          </QueryParamProvider>
        </Box>
      </ThemeProvider>
    </Router>
  );
}
export default App;
