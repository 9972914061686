import * as React from 'react'
import { AdAddressViewPageTop, AdAddressViewPageEmbed } from '../../ads/Ads'
import {
  Grid,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Link,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputBase,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { symbol } from '../../constants'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import LinkIcon from '@material-ui/icons/Link'
import FaceIcon from '@material-ui/icons/FaceOutlined'
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined'
import DoneIcon from '@material-ui/icons/Done'
import { WalletType, useMetadata } from '../../hooks/useMetadata'
import { styled } from '@material-ui/core/styles'
import useTokenApi from '../../hooks/useTokenApi'
import { getMetadata } from '../../hooks/useMetadata'
import LanguageSharpIcon from '@material-ui/icons/LanguageSharp'
import EmailIcon from '@material-ui/icons/Email'
import DescriptionIcon from '@material-ui/icons/Description'
import GitHubIcon from '@material-ui/icons/GitHub'
import { BiLogoDiscord } from 'react-icons/bi'
import { BiLogoTwitter } from 'react-icons/bi'
import { BiLogoTelegram } from 'react-icons/bi'
import { BiBadgeCheck } from 'react-icons/bi'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import useTokenBalances, {
  useTokenPrices,
  useTokensOfAccount,
} from '../../hooks/useTokenBalances'
import LaunchIcon from '@material-ui/icons/Launch'

export interface IAddressViewProps {
  address: string
  balance: number
  txCount: number
  code: string
}

const formatOptions = { maximumFractionDigits: 2 }
const formatOptionsBalance = { maximumFractionDigits: 4 }

// Stylers

const formatBalance = (
  balance: number,
  options: { maximumFractionDigits: number },
  unit = ''
) => {
  const intValue = Intl.NumberFormat('en-US', options).format(balance ?? 0)
  if (balance > 0 && intValue === '0') return `< ${unit}0.01`
  else return unit + intValue
}

const tableStyle = {
  borderBottom: '1px solid rgba(150,150,150,0.1)',
  fontSize: '10pt',
}
const tableStyleLast = {
  border: '0px',
  fontSize: '10pt',
}

const styleIcon = {
  marginRight: '5px',
}

const styleIconCheck = {
  fontSize: '16pt',
  marginTop: '2px',
  marginRight: '5px',
}

const styleDashIcon = {
  marginBottom: '-4px',
  marginRight: '5px',
}

const styleImg = {
  marginRight: '5px',
  boxShadow: '0px 0px 10px rgba(144, 144, 144, 0.02)',
  borderRadius: '50%',
}

const styleIconCopy = {
  fontSize: '10pt',
  margin: '7px auto 7px 5px',
  cursor: 'pointer',
  color: '#969696',
}

const styleTokenBalance = {
  fontSize: '9pt',
  color: '#969696',
}

const styleMenuItem = {
  paddingTop: '17px',
}

const styleTokenImgBalance = {
  width: '13px',
  height: '13px',
  margin: '2px 5px auto auto',
}

// const styleCard = {
//   margin: '15px auto 13px auto',
//   borderRadius: '10px',
//   padding: '15px',
//   boxShadow: '0px 0px 10px rgba(144, 144, 144, 0.02)',
//   width: '100%',
//   fontSize: '13pt',
//   whiteSpace: ,
//   // whiteSpace: 'nowrap',
// };

const styleDashCard = {
  width: '100%',
  height: '100%',
  maxWidth: '1320px',
  margin: 'auto',
  borderRadius: '10px',
  padding: '15px',
  display: 'flex',
  flex: '32%',
  boxShadow: '0px 0px 10px rgba(144, 144, 144, 0.02)',
}

const styleDashHeader = {
  fontSize: '11pt',
  margin: 'auto auto 10px auto',
}

const styleDashLink = {
  fontSize: '10pt',
}

const styleLinkIcons = {
  color: '#4183C4',
  fontSize: '14pt',
  margin: '2px',
}

const styleAdIcon = {
  fontSize: '8pt',
  margin: '2px',
}

const styleWarning = {
  background: 'rgba(255, 255, 153, 0.2',
  borderTop: 'solid 1px rgba(255, 255, 153)',
  borderBottom: 'solid 1px rgba(255, 255, 153)',
  padding: '20px',
  margin: '10px auto 10px auto',
  width: '100%',
}

const styleIconWarning = {
  marginBottom: '-5px',
}

const StyledTag = styled('span')({
  background: 'rgb(240 241 244)',
  color: '#00001f',
  border: '1px solid #ccced1',
  padding: '8px 11px',
  borderRadius: 20,
  maxWidth: 90,
  fontSize: '8pt',
  margin: '-10px 5px 5px 5px',
})

// const StyledTag = styled.span`
//   background: rgba(0, 161, 134, 0.1);
//   color: #00a186;
//   border: 1px solid #00a186;
//   padding: 5px;
//   borderradius: 5px;
//   maxwidth: 90px;
//   fontsize: 8pt;
//   margin: -10px 5px 5px 5px;
// `;

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(150, 150, 150, 0.1)',
    fontSize: 12,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 10,
      borderColor: 'rgba(150, 150, 150, 0.1)',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

function AddressView(props: IAddressViewProps) {
  const { address, balance, txCount } = props

  const [walletType, metadata] = useMetadata(address)

  const [copyState, setCopyState] = React.useState(false)

  const price = useTokenApi()

  const addressWebsite = React.useMemo(
    () => metadata?.links?.web ?? '#',
    [metadata]
  )
  const addressEmail = React.useMemo(
    () => metadata?.links?.email ?? '#',
    [metadata]
  )

  const tokenName = React.useMemo(
    () => metadata?.tokenNameOverwrite ?? 'Not Available',
    [metadata]
  )
  const tokenSymbol = React.useMemo(
    () => metadata?.tokenSymbolOverwrite ?? 'Not Available',
    [metadata]
  )
  const tokenSupply = React.useMemo(
    () => metadata?.totalSupplyOverwrite ?? 'Not Available',
    [metadata]
  )
  const tokenBurn = React.useMemo(
    () => metadata?.totalSupplyBurn ?? '0',
    [metadata]
  )
  const tokenDescription = React.useMemo(
    () => metadata?.description ?? 'Not Available',
    [metadata]
  )

  // Token Balances Constants
  const tokenAddresses = useTokensOfAccount(address)
  const tokens = useTokenBalances(address, tokenAddresses)
  const prices = useTokenPrices(tokenAddresses)

  const tokensWithPrice = React.useMemo(() => {
    if (!prices) return tokens
    else {
      const _tokensWithPrice = tokens.map((token) => {
        const { address } = token
        const price = prices.find(
          ({ address: _address }) => address === _address
        )
        if (price && price.priceUSD && !Number.isNaN(price.priceUSD)) {
          return { ...token, ...price }
        } else {
          return token
        }
      })
      _tokensWithPrice.sort((a: any, b: any) => {
        if (a.priceUSD && b.priceUSD) {
          if (a.priceUSD * a.balance > b.priceUSD * b.balance) {
            return -1
          } else if (a.priceUSD * a.balance === b.priceUSD * b.balance) return 0
          else return 1
        } else if (a.priceUSD) {
          return -1
        } else if (b.priceUSD) {
          return 1
        } else {
          return 0
        }
      })
      return _tokensWithPrice
    }
  }, [tokens, prices])

  const totalTokenBalance = React.useMemo(() => {
    return tokensWithPrice.reduce((memo, token) => {
      if (token.priceUSD) {
        memo += token.priceUSD * token.balance
      }
      return memo
    }, 0)
  }, [tokensWithPrice])
  // Display Link Functions

  function DisplayWalletLinks() {
    return (
      <span>
        {metadata?.links.web && (
          <>
          <a
            href={metadata?.links.web ?? '#'}
            title={addressWebsite}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LanguageSharpIcon style={styleLinkIcons} />
            
          </a>
          &nbsp;
          </>
        )}
        {metadata?.links.email && (
          <a href={'mailto:' + addressEmail} title={addressEmail}>
            <EmailIcon style={styleLinkIcons} />
          </a>
        )}
      </span>
    )
  }

  function DisplayTokenLinks() {
    return (
      <span>
        {metadata?.links.web && (
          <a
            href={addressWebsite}
            title="Website"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LanguageSharpIcon style={styleLinkIcons} />
            &nbsp;
          </a>
        )}

        {metadata?.links.email && (
          <a href={'mailto:' + addressEmail} title="Email">
            <EmailIcon style={styleLinkIcons} />
            &nbsp;
          </a>
        )}

        {metadata?.links.github && (
          <a
            href={metadata?.links.github ?? '#'}
            title="GitHub"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GitHubIcon style={styleLinkIcons} />
            &nbsp;
          </a>
        )}

        {metadata?.links.twitter && (
          <a
            href={metadata?.links?.twitter ?? '#'}
            title="Twitter"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BiLogoTwitter style={styleLinkIcons} />
            &nbsp;
          </a>
        )}

        {metadata?.links.discord && (
          <a
            href={metadata?.links?.discord ?? '#'}
            title="Discord"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BiLogoDiscord style={styleLinkIcons} />
            &nbsp;
          </a>
        )}

        {metadata?.links.telegram && (
          <a
            href={metadata?.links?.telegram ?? '#'}
            title="Telegram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BiLogoTelegram style={styleLinkIcons} />
            &nbsp;
          </a>
        )}

        {metadata?.links.whitepaper && (
          <a
            href={metadata?.links?.whitepaper ?? '#'}
            title="White Paper"
            target="_blank"
            rel="noopener noreferrer"
          >
            <DescriptionIcon style={styleLinkIcons} />
          </a>
        )}
      </span>
    )
  }

  function DisplayContractLinks(props: any) {
    return (
      <span>
        {metadata?.auditlink && (
          <a
            href={metadata?.auditLink ?? '#'}
            title="Contract Audit"
            target="_blank"
            rel="noopener noreferrer"
          >
            <DescriptionIcon style={styleLinkIcons} />
          </a>
        )}
      </span>
    )
  }

  const handleCopyAddress = () => {
    if (address) {
      navigator.clipboard.writeText(address).then(() => {
        setCopyState(true)
      })
    }
  }

  React.useEffect(() => {
    if (copyState) {
      setTimeout(() => {
        setCopyState(false)
      }, 1000)
    }
  }, [copyState])

  function IsVerified(props: any) {
    return (
      <span
        style={{
          color: '#4183C4',
          marginTop: '0px',
          marginLeft: '5px',
          maxWidth: '78px',
          fontSize: '8pt',
        }}
      >
        <BiBadgeCheck style={styleIconCheck} />
      </span>
    )
  }

  function IsNotVerified() {
    return <span />
  }

  function VerificationStatus() {
    if (metadata?.verified === 1) {
      return <IsVerified />
    } else {
      return <IsNotVerified />
    }
  }

  function DisplayWalletMoreInfo() {
    return (
      <div style={{ width: '100%' }}>
        <div style={tableStyle}>
          <p style={styleDashHeader}>
            <b>More Information</b>
          </p>
        </div>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={tableStyle}>Description:</TableCell>
              <TableCell style={tableStyle}>
                {tokenDescription ? tokenDescription : 'Not Available'}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={tableStyle}>Txn. Sent:</TableCell>
              <TableCell style={tableStyle}>{txCount}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={tableStyleLast}>More:</TableCell>
              <TableCell style={tableStyleLast}>
                <LinkIcon fontSize="small" style={styleDashIcon} />
                &nbsp;
                <Link
                  href={'https://blockscan.com/address/' + address}
                  target="_blank"
                  title="Search for multichain addresses usin Blockscan"
                >
                  <span style={styleDashLink}>Multichain</span>
                </Link>
                <span style={{ margin: 'auto 5px auto 5px' }} />
                <FaceIcon fontSize="small" style={styleDashIcon} />
                &nbsp;
                <Link
                  href={'https://debank.com/profile/' + address}
                  target="_blank"
                  title="View this wallet profile on DeBank"
                >
                  <span style={styleDashLink}>Profile</span>
                </Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    )
  }

  function DisplayTokenMoreInfo(props: any) {
    return (
      <div style={{ width: '100%' }}>
        <div style={tableStyle}>
          <p style={styleDashHeader}>
            <b>
              {tokenName} ({tokenSymbol})
            </b>
          </p>
        </div>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={tableStyle}>Max. Supply:</TableCell>
              <TableCell style={tableStyle}> {tokenSupply}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={tableStyle}>Burned Supply:</TableCell>
              <TableCell style={tableStyle}> {tokenBurn}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={tableStyleLast}>Description:</TableCell>
              <TableCell style={tableStyleLast}> {tokenDescription}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    )
  }

  function DisplayContractMoreInfo(props: any) {
    return (
      <div style={{ width: '100%' }}>
        <div style={tableStyle}>
          <p style={styleDashHeader}>
            <b>More Information</b>
          </p>
        </div>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={tableStyle}>Txn. Sent:</TableCell>
              <TableCell style={tableStyle}>{txCount}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={tableStyleLast}>Actions:</TableCell>
              <TableCell style={tableStyleLast}>
                <LinkIcon fontSize="small" style={styleDashIcon} />
                &nbsp;
                <Link
                  href={'https://blockscan.com/address/' + address}
                  target="_blank"
                  title="Search for multichain addresses usin Blockscan"
                >
                  <span style={styleDashLink}>Multichain</span>
                </Link>
                <span style={{ margin: 'auto 5px auto 5px' }} />
                <FaceIcon fontSize="small" style={styleDashIcon} />
                &nbsp;
                <Link
                  href={'https://debank.com/profile/' + address}
                  target="_blank"
                  title="View this wallet profile on DeBank"
                >
                  <span style={styleDashLink}>Profile</span>
                </Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    )
  }

  function DisplayTags(props: any) {
    const tags = getMetadata(props.address)?.categoryTags ?? []

    return (
      <span style={{ fontSize: '10pt', lineHeight: '1.5' }}>
        {tags.join(', ')}
      </span>
    )
  }

  return (
    <>
    
    {metadata?.warning && (
        <div style={styleWarning}>
          <ReportProblemIcon style={styleIconWarning} /> {metadata?.warning}
        </div>
      )}

    <Grid container>

      <AdAddressViewPageTop />

      <Box padding="10px" width="100%">
        <Card
          style={{
            margin: '15px auto 13px auto',
            borderRadius: '10px',
            padding: '30px',
            boxShadow: '0px 0px 10px rgba(144, 144, 144, 0.02)',
            width: '100%',
            fontSize: '1.1rem',
            flexWrap: 'wrap',
            display: 'flex',
            wordBreak: 'break-all',
          }}
        >
          {metadata?.tokenLogo ? (
            <img
              width={24}
              height={24}
              style={styleImg}
              src={'/metadata' + metadata.tokenLogo}
              alt="token-logo"
            />
          ) : (
            <SearchOutlinedIcon style={styleIcon} />
          )}
          <span>
            {' '}
            <b>
              {walletType === WalletType.CONTRACT
                ? 'Contract'
                : walletType === WalletType.TOKEN
                ? 'Token'
                : walletType === WalletType.WALLET
                ? 'Address'
                : 'Address'}
                &nbsp;
            </b>
          </span>
          <span
            style={{
              fontSize: '1.1rem',
              fontWeight: 'normal',
              display: 'flex',
              margin: '0',
            }}
          >
            
            {address}
            <VerificationStatus />
            {copyState ? (
              <DoneIcon fontSize="small" style={styleIconCopy} />
            ) : (
              <FileCopyIcon
                fontSize="small"
                style={styleIconCopy}
                onClick={() => handleCopyAddress()}
              />
            )}
            
          </span>
          {metadata?.nameTag && (
            <span style={{ margin: 'auto 10px' }}>
            <StyledTag>{metadata.nameTag}</StyledTag>
            </span>
        )}
          
        </Card>
      </Box>
      <p
        style={{
          fontSize: '10pt',
          margin: '-20px 10px 10px 10px',
          textAlign: 'right',
          width: '100%',
          padding: 10,
        }}
      >
        <a
          href="mailto:team@restratagem.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Want to report this address?
        </a>
      </p>

      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
        }}
      >
        <Grid item xs={12} sm={6} md={4} style={{ padding: 10 }}>
          <Card style={styleDashCard}>
            <div style={{ width: '100%' }}>
              <div style={tableStyle}>
                <p style={styleDashHeader}>
                  <b>Overview</b>
                  <span
                    style={{
                      fontSize: '7pt',
                      color: '#969696',
                      marginLeft: '5px',
                    }}
                  >
                    NET WORTH:
                    {formatBalance(
                      Number(price * balance) + totalTokenBalance,
                      formatOptions,
                      '$'
                    )}
                  </span>
                </p>
              </div>

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={tableStyle}>Balance:</TableCell>
                    <TableCell style={tableStyle}>
                      {Intl.NumberFormat('en-US', formatOptionsBalance).format(
                        Number(balance)
                      )}{' '}
                      {symbol}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell style={tableStyle}>{symbol} Value:</TableCell>
                    <TableCell style={tableStyle}>
                      $
                      {Intl.NumberFormat('en-US', formatOptions).format(
                        Number(price * balance)
                      )}{' '}
                      <span style={{ fontSize: '8pt' }}>
                        @ ${price.toFixed(3)}/{symbol}
                      </span>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell style={tableStyleLast}>Tokens:</TableCell>
                    <TableCell style={tableStyleLast}>
                      <FormControl>
                        {/* Need to make the select placeholder to show calculated $ for tokens. */}
                        <Select
                          id="token"
                          label="Tokens"
                          variant="outlined"
                          defaultValue={'select'}
                          input={<BootstrapInput />}
                          style={{ width: '100%', minWidth: '200px' }}
                        >
                          {/*
                          Display up to 4 decimals and 1,000,000 tokens
                          If more than 1,000,000 use >1,000,000 or 1,000,000+
                          */}
                          <MenuItem value="select" disabled>
                            <em>
                              {'>'}${totalTokenBalance.toFixed(2)} (Tokens:{' '}
                              {tokensWithPrice.length})
                            </em>
                          </MenuItem>
                          {tokensWithPrice.map((token) => (
                            <MenuItem
                              value={token.tokenNameOverwrite}
                              style={styleMenuItem}
                              onClick={() =>
                                window.open(
                                  '/address/' + token.address,
                                  '_self'
                                )
                              }
                            >
                              <p>
                                {/* render token image */}
                                <img
                                  src={`../../metadata${token.tokenLogo}`}
                                  style={styleTokenImgBalance}
                                  alt="token-logo"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null // prevents looping
                                    currentTarget.src =
                                      '../../metadata/img/unknown-token-logo.png'
                                  }}
                                />
                                <b>
                                  {token.tokenNameOverwrite} (
                                  {token.tokenSymbolOverwrite})
                                </b>
                                <p style={styleTokenBalance}>
                                  {/* render token balance */}
                                  {Intl.NumberFormat(
                                    'en-US',
                                    formatOptions
                                  ).format(token.balance)}{' '}
                                  {token.tokenSymbolOverwrite}{' '}
                                  {/* render current market price */}@
                                  {formatBalance(
                                    token.priceUSD ?? 0,
                                    formatOptions,
                                    '$'
                                  )}
                                  {/* calculate and render token net worth */}(
                                  {formatBalance(
                                    Number(
                                      (token.priceUSD ?? 0) * token.balance
                                    ),
                                    formatOptions,
                                    '$'
                                  )}
                                  )
                                </p>
                              </p>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} style={{ padding: 10 }}>
          <Card style={styleDashCard}>
            {walletType === WalletType.CONTRACT ? (
              <DisplayContractMoreInfo />
            ) : walletType === WalletType.TOKEN ? (
              <DisplayTokenMoreInfo />
            ) : walletType === WalletType.WALLET ? (
              <DisplayWalletMoreInfo />
            ) : (
              <DisplayWalletMoreInfo />
            )}
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} style={{ padding: 10 }}>
          <Card style={styleDashCard}>
            <div style={{ width: '100%' }}>
              <div style={tableStyle}>
                <p style={styleDashHeader}>
                  <b>Tags & Links</b>
                </p>
              </div>

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={tableStyle}>Tags:</TableCell>
                    <TableCell style={tableStyle}>
                      <DisplayTags address={address} />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell style={tableStyle}>Links:</TableCell>
                    <TableCell style={tableStyle}>
                      {walletType === WalletType.CONTRACT ? (
                        <DisplayContractLinks />
                      ) : walletType === WalletType.TOKEN ? (
                        <DisplayTokenLinks />
                      ) : walletType === WalletType.WALLET ? (
                        <DisplayWalletLinks />
                      ) : (
                        'Not Available'
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell style={tableStyleLast}>
                      Ad <LaunchIcon style={styleAdIcon} />
                    </TableCell>
                    <TableCell style={tableStyleLast}>
                      <AdAddressViewPageEmbed />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Grid>
    </>
  )
}

export default AddressView
