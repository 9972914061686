import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
// import grey from '@material-ui/core/colors/grey';

export const lightTheme = responsiveFontSizes(
  createMuiTheme({
    props: {
      MuiAppBar: {
        position: 'sticky',
      },
      MuiCard: {
        elevation: 0,
      },
    },
    overrides: {
      MuiAppBar: {
        root: {
          background: '#222 !important',
        },
      },
    },
    palette: {
      secondary: {
        main: '#EAEBED',
      },
      background: {
        default: '#FBFCFE',
      },
    },
  })
)

export const darkTheme = responsiveFontSizes(
  createMuiTheme({
    props: {
      MuiAppBar: {
        position: 'sticky',
      },
      MuiCard: {
        elevation: 0,
      },
    },
    palette: {
      type: 'dark',
      secondary: {
        main: '#FFF',
      },
      background: {
        default: '#161616',
        paper: '#222',
      },
    },
    overrides: {
      // Header bar
      MuiAppBar: {
        root: {
          background: '#222 !important',
        },
      },
      MuiTable: {
        root: {
          background: 'transparent !important',
        },
      },
      MuiTypography: {
        root: {
          color: '#FFF',
        },
      },
    },
  })
)

export default {
  darkTheme,
  lightTheme,
}
