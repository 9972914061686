import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import { Provider } from 'react-redux';
import App from './App';
import Footer from './containers/Footer';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { ReusableProvider } from 'reusable';
import './i18n';
import { NetworkContextName } from './constants';
import getLibrary from './utils/getLibrary';
import MulticallUpdater from './state/multicall/updater';
import ApplicationUpdater from './state/application/updater';
import XenwaveUpdater from './state/xenwave/updater';
import store from './state';

function Updaters() {
  return (
    <>
      <MulticallUpdater />
      <ApplicationUpdater />
      <XenwaveUpdater />
    </>
  );
}

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <ReusableProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <Provider store={store}>
            <Updaters />
            <App />
            <Footer />
          </Provider>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </ReusableProvider>
  </I18nextProvider>,
  document.getElementById('root') as HTMLElement
);
