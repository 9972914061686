export default {
  // Moment integration
  'Timestamp Date': '{{date, date}}',
  // App Bar
  'Change Language': '更改语言',
  'Enter an Address, Transaction Hash or Block Number':
    '输入地址 交易哈希或区块号',
  'JSON-RPC API Documentation': 'JSON-RPC API 文档',
  'Expedition Github': 'Expedition Github',
  'Toggle Dark Mode': '切换黑暗模式',
  // Dashboard
  'Block Height': '区块高度',
  'Chain ID': 'Chain ID',
  Syncing: '同步中',
  'Gas Price': 'Gas 费用',
  'Network Hash Rate': '算力',
  Peers: '端点',
  'Hash Rate': 'Hash Rate',
  'Transaction Count': 'Transaction Count',
  'Gas Usage': 'Gas Usage',
  Uncles: 'Uncles',
  'Top Miners': 'Top Miners',
  'Top Miners by extraData': 'Top Miners by extraData',
  'Top Miners by Address': 'Top Miners by Address',
  // block list
  'Block Number': '区块编号',
  Hash: '哈希',
  Timestamp: '时间戳',
  Transactions: '交易',
  // block view
  Number: '数量',
  ParentHash: '父哈希',
  Miner: '矿工',
  Nonce: '随机数',
  Difficulty: '难度',
  'Extra Data': '额外数据',
  'State Root': '状态根',
  'Transaction Root': '交易根',
  'Receipts Root': '接收根',
  // transaction view
  Block: '区块',
  'Gas Used': 'Gas 消耗',
  'Cumulative Gas Used': '累计Gas消耗',
  'Value in Ether': 'Ether值',
  From: '从',
  To: '到',
  'Contract Address': '合约地址',
  'Transaction Index': '交易索引',
  'Receipt Status': '接收状态',
  'Receipt Logs': '接收日志',
  // address view
  Address: '地址',
  Balance: '余额',
  Code: '代码',
  'No Transactions for this block range.':
    'No Transactions for this block range.',
  // configuration menu
  Configuration: '配置',
  Back: '返回',
  'Ethereum RPC': 'Ethereum RPC',
  'Service Runner RPC': 'Service Runner RPC',
}
