import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Card, Grid, Tooltip, TextField } from '@material-ui/core';
import { hexToNumber /* hexToString */ } from '@etclabscore/eserialize';
import { useHistory } from 'react-router-dom';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import CheckCircle from '@material-ui/icons/CheckCircle';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import useEthRPCStore from '../../stores/useEthRPCStore';
import {
  secondsToDhms,
  useBlockByBlockNumber,
  useBlockNumber,
} from '../../helpers';
import { gweiUnit, symbol } from '../../constants';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import useTokenApi from '../../hooks/useTokenApi';
import { getMetadata } from '../../hooks/useMetadata';
import { useTokenTransfersOfTx } from '../../hooks/useTransfers';
import UnknownTokenIcon from '../../img/unknown-token-logo.png';
// import DoneIcon from '@material-ui/icons/Done';

const formatOptions = { maximumFractionDigits: 2 };
const formatOptionsToken = { maximumFractionDigits: 6 };

const unit = require('ethjs-unit'); //tslint:disable-line
const tableStyle = {
  fontSize: '10pt',
  'text-align': 'start',
  'word-break': 'break-all',
  paddingTop: 10,
  paddingBottom: 10,
};
const tableStyleSticker = {
  fontSize: '10pt',
  'text-align': 'start',
  'word-break': 'break-all',
  paddingTop: 10,
  paddingBottom: 10,
  marginLeft: -7,
};
const tableStyleLast = {
  border: '0px',
  fontSize: '10pt',
  'text-align': 'start',
  'word-break': 'break-all',
  paddingTop: 10,
  paddingBottom: 10,
};
const iconStyle = {
  fontSize: '10pt',
  margin: 'auto 3px -2px 3px',
};

const iconStyleCopy = {
  fontSize: '10pt',
  margin: 'auto 3px -2px 3px',
  color: '#969696',
  cursor: 'pointer',
};

export interface ITxViewProps {
  tx: any;
  receipt: any | null;
}

const options: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: true,
  timeZone: 'UTC',
  timeZoneName: 'short',
};

function getAmountWithDecimals(amount: number, tokenDecimals: any) {
  // Get token decimals from the transaction object
  const parsedTokenDecimals = parseInt(tokenDecimals || '', 10);
  // Handle invalid input
  if (isNaN(parsedTokenDecimals) || parsedTokenDecimals <= 0) {
    return amount;
  }
  // Calculate the corresponding number of zeros

  return amount / 10 ** parsedTokenDecimals;
}

function TxView(props: ITxViewProps) {
  const { tx, receipt } = props;
  //  const { t } = useTranslation();
  const [erpc] = useEthRPCStore();
  const [blockNumber] = useBlockNumber(erpc);
  const [copyState, setCopyState] = React.useState(false);
  const price = useTokenApi();

  const tokenTransfers = useTokenTransfersOfTx(tx?.hash?.toString());

  const handleCopyHash = () => {
    if (tx.hash) {
      navigator.clipboard.writeText(tx.hash).then(() => {
        setCopyState(true);
      });
    }
  };

  const handleCopyFrom = () => {
    if (tx.from) {
      navigator.clipboard.writeText(tx.from).then(() => {
        setCopyState(true);
      });
    }
  };

  const handleCopyTo = () => {
    if (tx.to) {
      navigator.clipboard.writeText(tx.to).then(() => {
        setCopyState(true);
      });
    }
  };

  React.useEffect(() => {
    if (copyState) {
      setTimeout(() => {
        setCopyState(false);
      }, 1000);
    }
  }, [copyState]);

  const block = useBlockByBlockNumber(Number(tx?.blockNumber), erpc);
  const latestBlock = useBlockByBlockNumber(blockNumber, erpc);

  const timestamp = React.useMemo(() => {
    if (block && block.timestamp) {
      return Number(block.timestamp) * 1000;
    } else {
      return null;
    }
  }, [block]);

  const UTCTimeString = React.useMemo(
    () =>
      timestamp
        ? new Date(Number(timestamp)).toLocaleDateString('en-US', options)
        : null,
    [timestamp]
  );

  const deltaTime = React.useMemo(() => {
    if (!latestBlock || !timestamp) return null;

    return secondsToDhms(Number(latestBlock.timestamp) - timestamp / 1000);
  }, [latestBlock, timestamp]);

  const history = useHistory();
  if (!tx) {
    return null;
  }

  function TxSuccess(props: any) {
    return (
      <div
        style={{
          background: 'rgba(0,161,134,0.1',
          color: '#00A186',
          border: '1px solid #00A186',
          padding: '5px',
          borderRadius: '5px',
          maxWidth: '78px',
          fontSize: '8pt',
        }}
      >
        <CheckCircle style={iconStyle} />
        Success
      </div>
    );
  }

  function TxFail(props: any) {
    return (
      <div
        style={{
          background: 'rgba(229,39,78,0.1',
          color: '#E5274E',
          border: '1px solid #E5274E',
          padding: '5px',
          borderRadius: '5px',
          maxWidth: '55px',
          fontSize: '8pt',
        }}
      >
        <RemoveCircle style={iconStyle} />
        Fail
      </div>
    );
  }

  function TxStatus(props: any) {
    const success = hexToNumber(receipt.status);
    if (success) {
      return <TxSuccess />;
    }
    return <TxFail />;
  }

  return (
    <div
      style={{
        margin: 'auto',
        marginTop: 0,
        maxWidth: '1320px',
        textAlign: 'center',
      }}
    >
      <p
        style={{
          textAlign: 'left',
          margin: '20px 0px 20px 0px',
          fontSize: '15pt',
          fontWeight: 'normal',
        }}
      >
        Transaction Details
      </p>

      <Grid container>
        <Grid item>
          <Button
            variant='outlined'
            style={{
              position: 'relative',
              background: '#4183C4',
              color: '#FFF',
              border: '0px',
              borderRadius: '5px',
              margin: '5px',
              textAlign: 'center',
            }}
          >
            Overview
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              history.push(`/tx/${tx.hash}/raw`);
            }}
            variant='outlined'
            style={{
              position: 'relative',
              color: '#4183C4',
              borderRadius: '5px',
              margin: '5px',
              textAlign: 'center',
            }}
          >
            Raw Data
          </Button>
        </Grid>
      </Grid>

      <Card
        style={{
          width: '1320px',
          maxWidth: '90vw',
          overflowX: 'auto',
          margin: '20px auto 20px auto',
          borderRadius: '10px',
          padding: '15px',
          boxShadow: '0px 0px 10px rgba(144, 144, 144, 0.02)',
        }}
      >
        <Grid container>
          <Grid
            item
            container
            style={{ borderBottom: '1px solid rgba(150,150,150,0.1)' }}
          >
            <Grid item xs={12} sm={4} md={3} style={tableStyle}>
              <Tooltip title='A transaction hash is a unique 66 character identifier that is generated when a transaction is executed. Think of it as the receipt of your transaction.'>
                <InfoOutlined style={iconStyle} />
              </Tooltip>
              Transaction Hash:
            </Grid>
            <Grid item xs={12} sm={8} md={9} style={tableStyle}>
              {tx.hash}
              &nbsp;
              {copyState ? (
                <FileCopyIcon fontSize='small' style={iconStyleCopy} />
              ) : (
                <FileCopyIcon
                  fontSize='small'
                  style={iconStyleCopy}
                  onClick={() => handleCopyHash()}
                />
              )}
            </Grid>
          </Grid>

          <Grid
            item
            container
            style={{ borderBottom: '1px solid rgba(150,150,150,0.1)' }}
          >
            <Grid item xs={4} sm={4} md={3} style={tableStyle}>
              <Tooltip title='The status indicates if the transacation was successfully processed.'>
                <InfoOutlined style={iconStyle} />
              </Tooltip>
              Status:
            </Grid>
            <Grid item xs={8} sm={8} md={9} style={tableStyle}>
              <TxStatus />
            </Grid>
          </Grid>

          <Grid
            item
            container
            style={{ borderBottom: '1px solid rgba(150,150,150,0.1)' }}
          >
            <Grid item xs={12} sm={4} md={3} style={tableStyle}>
              <Tooltip title='Number of the block in which the transaction was recorded. The block confirmations indicate how many blocks were produced and confirmed after the transaction took place.'>
                <InfoOutlined style={iconStyle} />
              </Tooltip>
              Block:
            </Grid>
            <Grid item xs={12} sm={8} md={9} style={tableStyle}>
              <span style={{ color: '#00A186' }}>
                <CheckCircle style={iconStyle} />
              </span>
              <RouterLink to={`/block/${tx.blockHash}`}>
                {hexToNumber(tx.blockNumber)}
              </RouterLink>
              <span
                style={{
                  background: 'rgba(0,161,134,0.1)',
                  color: '#00A186',
                  border: '1px solid #00A186',
                  padding: '5px',
                  borderRadius: '5px',
                  maxWidth: '90px',
                  fontSize: '8pt',
                  margin: '-10px 5px 5px 5px',
                  whiteSpace: 'nowrap',
                }}
              >
                {Number(blockNumber) - tx.blockNumber} Block Confirmations
              </span>
            </Grid>
          </Grid>

          <Grid
            item
            container
            style={{ borderBottom: '1px solid rgba(150,150,150,0.1)' }}
          >
            <Grid item xs={12} sm={4} md={3} style={tableStyle}>
              <Tooltip title='The date and time in which the transaction was processed'>
                <InfoOutlined style={iconStyle} />
              </Tooltip>
              Timestamp:
            </Grid>
            <Grid item xs={12} sm={8} md={9} style={tableStyle}>
              <AccessTimeIcon fontSize='small' style={iconStyle} />
              &nbsp;
              {UTCTimeString && deltaTime
                ? `${deltaTime} ago (${UTCTimeString})`
                : ''}
            </Grid>
          </Grid>

          <Grid
            item
            container
            style={{ borderBottom: '1px solid rgba(150,150,150,0.1)' }}
          >
            <Grid item xs={12} sm={4} md={3} style={tableStyle}>
              <Tooltip title='The address sending the transaction.'>
                <InfoOutlined style={iconStyle} />
              </Tooltip>
              From:
            </Grid>
            <Grid item xs={12} sm={8} md={9} style={tableStyle}>
              <RouterLink to={`/address/${tx.from}`}>
                {getMetadata(tx.from)?.nameTag ?? tx.from}
              </RouterLink>
              &nbsp;
              {copyState ? (
                <FileCopyIcon fontSize='small' style={iconStyleCopy} />
              ) : (
                <FileCopyIcon
                  fontSize='small'
                  style={iconStyleCopy}
                  onClick={() => handleCopyFrom()}
                />
              )}
            </Grid>
          </Grid>

          <Grid
            item
            container
            style={{ borderBottom: '1px solid rgba(150,150,150,0.1)' }}
          >
            <Grid item xs={12} sm={4} md={3} style={tableStyle}>
              <Tooltip title='The address receiving the transaction.'>
                <InfoOutlined style={iconStyle} />
              </Tooltip>
              To{tx.input !== '0x' && <span>/Interacted With</span>}:
            </Grid>
            <Grid item xs={12} sm={8} md={9} style={tableStyle}>
              <RouterLink to={`/address/${tx.to}`}>
                {getMetadata(tx.to)?.nameTag ?? tx.to}
              </RouterLink>
              &nbsp;
              {copyState ? (
                <FileCopyIcon fontSize='small' style={iconStyleCopy} />
              ) : (
                <FileCopyIcon
                  fontSize='small'
                  style={iconStyleCopy}
                  onClick={() => handleCopyTo()}
                />
              )}
            </Grid>
          </Grid>

          {tx.input !== '0x' && (
            <Grid
              item
              container
              style={{ borderBottom: '1px solid rgba(150,150,150,0.1)' }}
            >
              <Grid item xs={12} sm={4} md={3} style={tableStyle}>
                <Tooltip title='The function called by the wallet to the contract, in hexadecimal representation.'>
                  <InfoOutlined style={iconStyle} />
                </Tooltip>
                Contract Method:
              </Grid>
              <Grid item xs={12} sm={8} md={9} style={tableStyleSticker}>
                <span
                  style={{
                    background: 'rgba(150,150,150,0.1',
                    color: '#969696',
                    border: '1px solid #969696',
                    padding: '5px',
                    borderRadius: '5px',
                    fontSize: '8pt',
                    margin: '-10px 5px 5px 5px',
                  }}
                >
                  {tx.input.substring(0, 10)}
                </span>
              </Grid>
            </Grid>
          )}

          {tokenTransfers && (
            <Grid
              item
              container
              style={{ borderBottom: '1px solid rgba(150,150,150,0.1)' }}
            >
              {tokenTransfers?.map(
                (
                  {
                    from,
                    to,
                    value,
                    token: { id: tokenAddress, decimals },
                  }: any,
                  index: number
                ) => (
                  <>
                    <Grid item xs={12} sm={4} md={3} style={tableStyle}>
                      {index === 0 ? (
                        <Tooltip title='A summary of the token transfers in the transaction.'>
                          <InfoOutlined style={iconStyle} />
                        </Tooltip>
                      ) : null}
                      {index === 0 ? 'Token Transfers:' : null}
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} style={tableStyle}>
                      <b>From</b>{' '}
                      <RouterLink to={`/address/${from?.id}`}>
                        {getMetadata(from?.id)?.tokenLogo && (
                          <img
                            src={`../../metadata/img/${from?.id}.png`}
                            style={{ width: '12px', height: '12px' }}
                            alt='token-logo'
                          />
                        )}{' '}
                        {getMetadata(from?.id)?.nameTag ??
                          from?.id.substring(0, 10) +
                            `...` +
                            from?.id.substring(32, 42)}
                      </RouterLink>{' '}
                      <b>To</b>{' '}
                      {getMetadata(to?.id)?.tokenLogo && (
                        <img
                          src={`../../metadata/img/${to?.id}.png`}
                          style={{ width: '12px', height: '12px' }}
                          alt='token-logo'
                        />
                      )}{' '}
                      <RouterLink to={`/address/${to?.id}`}>
                        {getMetadata(to?.id)?.nameTag ??
                          to?.id.substring(0, 10) +
                            `...` +
                            to?.id.substring(32, 42)}
                      </RouterLink>{' '}
                      <b>For</b>{' '}
                      {Intl.NumberFormat('en-US', formatOptionsToken).format(
                        getAmountWithDecimals(Number(value), decimals)
                      )}
                      <RouterLink to={`/address/${tokenAddress}`}>
                        {' '}
                        <img
                          src={
                            getMetadata(tokenAddress)?.tokenLogo
                              ? `../../metadata/img/${tokenAddress}.png`
                              : UnknownTokenIcon
                          }
                          style={{ width: '12px', height: '12px' }}
                          alt='token-logo'
                        />{' '}
                        {getMetadata(tokenAddress)?.tokenSymbolOverwrite ??
                          tx.to.substring(0, 10) +
                            `...` +
                            tx.to.substring(32, 42)}
                      </RouterLink>
                    </Grid>
                  </>
                )
              )}
            </Grid>
          )}

          <Grid
            item
            container
            style={{ borderBottom: '1px solid rgba(150,150,150,0.1)' }}
          >
            <Grid item xs={12} sm={4} md={3} style={tableStyle}>
              <Tooltip title='The value in native currency that was sent with the transaction.'>
                <InfoOutlined style={iconStyle} />
              </Tooltip>
              Value:
            </Grid>
            <Grid item xs={12} sm={8} md={9} style={tableStyle}>
              {unit.fromWei(tx.value, 'ether')} {symbol} ($
              {Intl.NumberFormat('en-US', formatOptions).format(
                Number(price * unit.fromWei(tx.value, 'ether'))
              )}
              )
            </Grid>
          </Grid>

          <Grid
            item
            container
            style={{ borderBottom: '1px solid rgba(150,150,150,0.1)' }}
          >
            <Grid item xs={12} sm={4} md={3} style={tableStyle}>
              <Tooltip title='The amount paid to validators to process the transaction.'>
                <InfoOutlined style={iconStyle} />
              </Tooltip>
              Transaction Fee:
            </Grid>
            <Grid item xs={12} sm={8} md={9} style={tableStyle}>
              {Number(
                (hexToNumber(receipt.gasUsed) *
                  hexToNumber(receipt.effectiveGasPrice)) /
                  1000000000000000000
              ).toFixed(9)}{' '}
              {symbol} ($
              {Number(
                (price *
                  hexToNumber(receipt.gasUsed) *
                  hexToNumber(receipt.effectiveGasPrice)) /
                  1000000000000000000
              ).toFixed(8)}
              )
            </Grid>
          </Grid>

          <Grid
            item
            container
            style={{ borderBottom: '0px solid rgba(150,150,150,0.1)' }}
          >
            <Grid item xs={12} sm={4} md={3} style={tableStyleLast}>
              <Tooltip title='Cost per unit of gast spent in the transaction.'>
                <InfoOutlined style={iconStyle} />
              </Tooltip>
              Gas Price:
            </Grid>
            <Grid item xs={12} sm={8} md={9} style={tableStyleLast}>
              {(Number(hexToNumber(tx.gasPrice)) / 1000000000).toFixed(2)}{' '}
              {gweiUnit} (
              {(Number(hexToNumber(tx.gasPrice)) / 1000000000000000000).toFixed(
                9
              )}{' '}
              {symbol} / $
              {Number(
                (price * hexToNumber(tx.gasPrice)) / 1000000000000000000
              ).toFixed(8)}
              )
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <Card
        style={{
          width: '1320px',
          maxWidth: '90vw',
          overflowX: 'auto',
          margin: '20px auto 20px auto',
          borderRadius: '10px',
          padding: '15px',
          boxShadow: '0px 0px 10px rgba(144, 144, 144, 0.02)',
        }}
      >
        <Grid container>
          <Grid item container>
            <Grid item xs={4} sm={4} md={3} style={tableStyle}>
              <h3 style={{ textAlign: 'left' }}>More Information</h3>
            </Grid>
          </Grid>

          {receipt.contractAddress && (
            <Grid
              item
              container
              style={{ borderBottom: '1px solid rgba(150,150,150,0.1)' }}
            >
              <Grid item xs={12} sm={4} md={3} style={tableStyle}>
                <Tooltip title='If the transaction was used to deply a smart contract, its address will be shown here.'>
                  <InfoOutlined style={iconStyle} />
                </Tooltip>
                Deployed Smart Contract:
              </Grid>
              <Grid item xs={12} sm={8} md={9} style={tableStyleSticker}>
                <span
                  style={{
                    background: 'rgba(150,150,150,0.1',
                    color: '#969696',
                    border: '1px solid #969696',
                    padding: '5px',
                    borderRadius: '5px',
                    fontSize: '8pt',
                    margin: '-10px 5px 5px 5px',
                  }}
                >
                  S/C&nbsp;
                  <RouterLink to={`/address/${receipt.contractAddress}`}>
                    {getMetadata(receipt.contractAddress)
                      ?.contractNameOverwrite ?? receipt.contractAddress}
                  </RouterLink>
                </span>
              </Grid>
            </Grid>
          )}

          {tx.input !== '0x' && (
            <Grid
              item
              container
              style={{ borderBottom: '1px solid rgba(150,150,150,0.1)' }}
            >
              <Grid item xs={12} sm={4} md={3} style={tableStyleLast}>
                <Tooltip title='Aditional data submited with the transaction. Normally used by smart contracts or by wallets to send a message to the recipient.'>
                  <InfoOutlined style={iconStyle} />
                </Tooltip>
                Raw Input Data:
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={9}
                style={{ ...tableStyleLast, overflow: 'auto' }}
              >
                <TextField
                  multiline
                  variant='outlined'
                  defaultValue={tx.input}
                  rows={4}
                  style={{ minWidth: '320px' }}
                />
              </Grid>
            </Grid>
          )}

          <Grid
            item
            container
            style={{ borderBottom: '1px solid rgba(150,150,150,0.1)' }}
          >
            <Grid item xs={4} sm={4} md={3} style={tableStyle}>
              <Tooltip title='A nonce is a random number that is generated and used only once in cryptographic communication. Its main purpose is to prevent double-spending attacks, which is an important security feature in blockchain technology.'>
                <InfoOutlined style={iconStyle} />
              </Tooltip>
              Nonce:
            </Grid>
            <Grid item xs={8} sm={8} md={9} style={tableStyle}>
              {hexToNumber(tx.nonce)}
            </Grid>
          </Grid>

          <Grid
            item
            container
            style={{ borderBottom: '0px solid rgba(150,150,150,0.1)' }}
          >
            <Grid item xs={12} sm={4} md={3} style={tableStyle}>
              <Tooltip title='The number representing the order in which this transaction was indexed within the block.'>
                <InfoOutlined style={iconStyle} />
              </Tooltip>
              Transaction Index:
            </Grid>
            <Grid item xs={12} sm={8} md={9} style={tableStyle}>
              {hexToNumber(tx.transactionIndex)}
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <div style={{ margin: '30px' }}>&nbsp;</div>
    </div>
  );
}

export default TxView;
