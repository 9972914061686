import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import LanguageSharpIcon from '@material-ui/icons/LanguageSharp';
import GitHubIcon from '@material-ui/icons/GitHub';
import { BiLogoDiscord, BiLogoTwitter, BiLogoTelegram } from 'react-icons/bi';
import EmailIcon from '@material-ui/icons/Email';
import Tokens from '../../metadata/token-list.json';

const headStyle = {
  borderBottom: '1px solid rgba(150,150,150,0.1)',
  fontSize: '10pt',
};

const tableStyle = {
  borderBottom: '1px solid rgba(150, 150, 150,0.1)',
  padding: '10px 10px 10px 18px',
};

interface Token {
  tokenLogo: string;
  tokenNameOverwrite: string;
  tokenSymbolOverwrite: string;
  tokenStandard: string;
}

interface TokenData {
  [tokenId: string]: Token;
}

const tokenData: TokenData = Tokens;

const iconStyle = {
  fontSize: '12pt',
};

function TokenListPage(props: any) {
  return (
    <div
      style={{
        overflowX: 'auto',
        margin: 'auto',
      }}
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={headStyle}></TableCell>
            <TableCell style={headStyle}>
              <b>Token Name</b>
            </TableCell>
            <TableCell style={headStyle}>
              <b>Symbol</b>
            </TableCell>
            <TableCell style={headStyle}>
              <b>Address</b>
            </TableCell>
            <TableCell style={headStyle}>
              <b>Links</b>
            </TableCell>
            <TableCell style={headStyle}>
              <b>Standard</b>
            </TableCell>
          </TableRow>

          {Object.keys(tokenData).map((tokenId, key) => {
            const token = tokenData[tokenId];

            return (
              <TableRow key={key} style={tableStyle}>
                <TableCell style={tableStyle}>
                  <img
                    alt=''
                    height='22'
                    src={`../../metadata${token.tokenLogo}`}
                    style={{ margin: 'auto' }}
                  />
                </TableCell>

                <TableCell component='th' scope='row' style={tableStyle}>
                  {token.tokenNameOverwrite ?? 'Unknown'}
                </TableCell>

                <TableCell style={tableStyle}>
                  {token.tokenSymbolOverwrite ?? 'Unknown'}
                </TableCell>

                <TableCell style={tableStyle}>
                  <p style={{ fontSize: '10pt' }}>
                    <RouterLink to={`/address/${tokenId}`}>
                      {tokenId}
                    </RouterLink>
                  </p>
                </TableCell>

                <TableCell style={tableStyle}>
                  <p style={{ fontSize: '10pt' }}>
                    <span>
                      <RouterLink to='#' target='_blank'>
                        <LanguageSharpIcon style={iconStyle} />{' '}
                      </RouterLink>
                      <RouterLink to='#' target='_blank'>
                        <EmailIcon style={iconStyle} />{' '}
                      </RouterLink>
                      <RouterLink to='#' target='_blank'>
                        <BiLogoDiscord style={iconStyle} />{' '}
                      </RouterLink>
                      <RouterLink to={'#'} target='_blank'>
                        <BiLogoTwitter style={iconStyle} />{' '}
                      </RouterLink>
                      <RouterLink to={'#'} target='_blank'>
                        <BiLogoTelegram style={iconStyle} />{' '}
                      </RouterLink>
                      <RouterLink to={'#'} target='_blank'>
                        <GitHubIcon style={iconStyle} />{' '}
                      </RouterLink>
                    </span>
                  </p>
                </TableCell>

                <TableCell style={tableStyle}>{token.tokenStandard ?? 'Unknown'}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default TokenListPage;
