import { useCallback, useEffect, useState } from 'react';
import useDebounce from '../../hooks/useDebounce';
import useIsWindowVisible from '../../hooks/useIsWindowVisible';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { client } from '../../apollo/client';
import { ETH_PRICE } from '../../apollo/queries';
import { updateEthPrice } from './actions';

dayjs.extend(utc);
dayjs.extend(weekOfYear);

const getEthPrice = async () => {
  let ethPrice = 0;

  try {
    let result = await client.query({
      query: ETH_PRICE(),
      fetchPolicy: 'cache-first',
    });

    const currentPrice = result?.data?.bundles[0]?.ethPrice;
    ethPrice = currentPrice;
  } catch (e) {
    console.log(e);
  }

  return ethPrice;
};

export default function Updater(): null {
  const dispatch = useDispatch();

  const windowVisible = useIsWindowVisible();

  const [state, setState] = useState<{
    etherPrice: number | undefined;
  }>({
    etherPrice: 0,
  });

  const etherPriceCallback = useCallback(
    (etherPrice: number) => {
      setState((state) => {
        if (typeof state.etherPrice !== 'number') return { etherPrice };
        return {
          etherPrice,
        };
      });
    },
    [setState]
  );

  // attach/detach listeners
  useEffect(() => {
    if (!windowVisible) return undefined;

    getEthPrice().then(etherPriceCallback);
    const handler = setInterval(() => {
      getEthPrice().then(etherPriceCallback);
    }, 30000);

    return () => {
      clearInterval(handler);
    };
  }, [etherPriceCallback, windowVisible]);

  const debouncedState = useDebounce(state, 100);

  // attach/detach listeners

  useEffect(() => {
    if (!debouncedState.etherPrice || !windowVisible) return;
    dispatch(
      updateEthPrice({
        etherPrice: debouncedState.etherPrice,
      })
    );
  }, [windowVisible, dispatch, debouncedState]);

  return null;
}
