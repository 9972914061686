import React from 'react';
import CheckPoint from '../metadata/checkpoint.json';
import ContractList from '../metadata/contract-list.json';
import TokenList from '../metadata/token-list.json';
import WalletList from '../metadata/wallet-list.json';

export enum WalletType {
  WALLET,
  TOKEN,
  CONTRACT,
}

const checkType = (address: string) => {
  const point = (CheckPoint as any)[address.toLowerCase()];
  if (!point) return null;
  else if (point.token === 1) return WalletType.TOKEN;
  else if (point.contract === 1) return WalletType.CONTRACT;
  else return WalletType.WALLET;
};

export const getMetadata = (
  address: string | undefined,
  _type?: WalletType | null
) => {
  if (!address) return null;

  const normalizedAddress = address.toLowerCase(); // Normalize the address

  let type = _type;

  if (type === undefined) type = checkType(normalizedAddress);
  if (type === WalletType.WALLET) {
    const metadata = (WalletList as any)[normalizedAddress];
    if (metadata) return metadata;
    else return null;
  } else if (type === WalletType.TOKEN) {
    const metadata = (TokenList as any)[normalizedAddress];
    if (metadata) return metadata;
    else return null;
  } else if (type === WalletType.CONTRACT) {
    const metadata = (ContractList as any)[normalizedAddress];
    if (metadata) return metadata;
    else return null;
  } else return null;
};


export const useMetadata = (address: string | undefined) => {
  const normalizedAddress = address?.toLowerCase(); // Normalize the address

  const type = React.useMemo(() => {
    if (normalizedAddress) return checkType(normalizedAddress);
    else return null;
  }, [normalizedAddress]);

  const metadata = React.useMemo(() => {
    if (normalizedAddress && type !== null) {
      return getMetadata(normalizedAddress, type);
    } else return null;
  }, [type, normalizedAddress]);

  return [type, metadata];
};

