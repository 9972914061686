import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { useMediaQuery } from '@material-ui/core';

const ResponsivePopover = (props: any) => {
  const matches = useMediaQuery('(min-width: 700px)');

  return (
    <>
      {matches ? (
        <Popper
          open={props.open}
          anchorEl={props.anchorEl}
          role={undefined}
          transition={props.transition}
          disablePortal={props.disablePortal}
        >
          {({
            TransitionProps,
            placement,
          }: {
            TransitionProps: any;
            placement: any;
          }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={props.handleClose}>
                  <MenuList
                    autoFocusItem={props.open}
                    id='menu-list-grow'
                    onKeyDown={props.handleListKeyDown}
                    style={{
                      padding: '15px',
                    }}
                  >
                    {props.children}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      ) : (
        <MenuList
          autoFocusItem={props.open}
          id='menu-list-grow'
          onKeyDown={props.handleListKeyDown}
          style={{
            color: '#FFF',
            height: props.open ? 'fit-content' : 0,
            overflow: 'hidden',
            padding: 0,
          }}
        >
          {props.children}
        </MenuList>
      )}
    </>
  );
};

export default ResponsivePopover;
