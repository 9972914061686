import React, { useState, createContext, useEffect } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom';
import { styled } from '@material-ui/styles';
import { Grid, Theme, useMediaQuery } from '@material-ui/core';
import useDarkMode from 'use-dark-mode';

const HeaderLinks = styled(Grid)(({ theme }: { theme: Theme }) => ({
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: '1rem 0 1rem 1rem',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 20,
    top: 0,
  },
}));

const MobileMenuWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '1rem 0 1rem 1rem',
  position: 'absolute',
  right: 20,
  top: -10,
}));

const MenuContent = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'fixed',
  top: 0,
  width: '100%',
  left: 0,
  right: 0,
  bottom: 0,
  padding: 20,
  paddingTop: 40,
  display: 'flex',
  gap: 30,
  flexDirection: 'column',
  backgroundColor: 'black',
  overflow: 'auto',
}));

const CloseIcon = styled('div')(() => ({
  position: 'absolute',
  right: '1rem',
  top: 14,
  '&:hover': {
    cursor: 'pointer',
    opacity: 0.6,
  },
}));

const CloseColor = styled(ClearIcon)(() => ({
  path: {
    stroke: 'black',
  },
}));

interface ContextInterface {
  open: boolean;
  setOpen: Function;
}

export const MobileMenuContext = createContext<ContextInterface>({
  open: false,
  setOpen: () => {},
});

const MobileMenu = ({ children }: { children: any }) => {
  const [open, setOpen] = useState(false);
  const darkMode = useDarkMode();
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      setOpen(false);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <MobileMenuContext.Provider value={{ open, setOpen }}>
      <MobileMenuWrapper>
        <div onClick={() => setOpen(!open)}>
          <MenuIcon
            style={{
              width: 35,
              height: 50,
              color: darkMode ? 'white' : 'black',
            }}
          />
        </div>
        {open && (
          <MenuContent style={{ color: darkMode ? 'white' : 'black' }}>
            <CloseIcon onClick={() => setOpen(false)}>
              <CloseColor />
            </CloseIcon>
            {children}
          </MenuContent>
        )}
      </MobileMenuWrapper>
    </MobileMenuContext.Provider>
  );
};

const ResponsiveHeaderLinks = ({ children }: { children: any }) => {
  const matches = useMediaQuery('(min-width: 700px)');
  return (
    <>
      {matches ? (
        <HeaderLinks
          container
          style={{ flexWrap: 'nowrap', width: 'fit-content' }}
        >
          {children}
        </HeaderLinks>
      ) : (
        <MobileMenu>{children}</MobileMenu>
      )}
    </>
  );
};

export default ResponsiveHeaderLinks;
