import { useState, useEffect } from 'react';
import { erc20Client } from '../apollo/client';
import { TOKEN_TRANSFERS, TOKEN_TRANSFERS_OF_ACCOUNT } from '../apollo/queries';

const useTokenTransfersOfTx = (tx: string | undefined) => {
  const [transfers, setTransfers] = useState<any>();

  useEffect(() => {
    (async () => {
      if (tx) {
        try {
          let result: any = await erc20Client.query({
            query: TOKEN_TRANSFERS(tx),
            fetchPolicy: 'no-cache',
          });
          if (result?.data?.tokenTransfers) {
            setTransfers(result?.data?.tokenTransfers);
          }
        } catch (err) {
          console.log(err);
        }
      }
    })();
  }, [tx]);

  return transfers;
};

const useTokenTransfersOfAccount = (address: string | undefined) => {
  const [transfers, setTransfers] = useState<any>();

  useEffect(() => {
    (async () => {
      if (address) {
        try {
          let result: any = await erc20Client.query({
            query: TOKEN_TRANSFERS_OF_ACCOUNT(address),
            fetchPolicy: 'no-cache',
          });
          if (result?.data?.tokenTransfers) {
            setTransfers(result?.data?.tokenTransfers);
          }
        } catch (err) {
          console.log(err);
        }
      }
    })();
  }, [address]);

  return transfers;
};

export { useTokenTransfersOfTx, useTokenTransfersOfAccount };
