import * as React from 'react';
import { Typography, Card, CardContent } from '@material-ui/core';

interface IProps {
  children: any;
  title: string;
}

const ChartCard: React.FC<IProps> = (props) => {
  return (
    <Card
      style={{
        textAlign: 'center',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(144, 144, 144, 0.02)',
        width: '100%',
        minWidth: '300px',
      }}
      elevation={0}
    >
      <CardContent>
        <Typography variant='h6'>{props.title}</Typography>
        {props.children}
      </CardContent>
    </Card>
  );
};

export default ChartCard;
