import { Box } from '@material-ui/core'
import * as React from 'react'
import TxView from '../components/TxView'
import useEthRPCStore from '../stores/useEthRPCStore'
import {
  Transaction as ITransaction,
  TransactionReceiptOrNull as ITransactionReceipt,
} from '@etclabscore/ethereum-json-rpc'
import LoadingImage from '../img/loading.gif'

export default function TransactionContainer(props: any) {
  const hash = props.match.params.hash
  const [erpc] = useEthRPCStore()
  const [transaction, setTransaction] = React.useState<ITransaction>()
  const [receipt, setReceipt] = React.useState<ITransactionReceipt>()

  React.useEffect(() => {
    if (!erpc) {
      return
    }
    erpc.eth_getTransactionByHash(hash).then((tx) => {
      if (tx === null) {
        return
      }
      setTransaction(tx)
    })
  }, [hash, erpc])

  React.useEffect(() => {
    if (!erpc) {
      return
    }
    erpc.eth_getTransactionReceipt(hash).then((r) => {
      if (r === null) {
        return
      }
      setReceipt(r)
    })
  }, [hash, erpc])

  if (!transaction || !receipt) {
    return <Box display="flex" justifyContent="center" height='100%'><img alt="Logo" src={LoadingImage} /></Box>
  }

  return <TxView tx={transaction} receipt={receipt} />
}
