import { useMemo } from 'react';
import { useActiveWeb3React } from '.';
import { getContract } from '../utils';
import { Contract } from '@ethersproject/contracts';
import {
  MULTICALL_ABI,
  MULTICALL_CONTRACT_ADDRESS,
} from '../constants/multicall';
import { ERC20_ABI } from '../constants/abis/erc20';

// returns null on errors
function useContract(
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true
): Contract | null {
  const { library, account } = useActiveWeb3React();

  return useMemo(() => {
    if (!address || !ABI || !library) return null;
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [address, ABI, library, withSignerIfPossible, account]);
}

export function useMulticallContract(): Contract | null {
  return useContract(MULTICALL_CONTRACT_ADDRESS, MULTICALL_ABI, false);
}

export function useTokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean
): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible);
}
