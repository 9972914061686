import React, { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useTranslation } from 'react-i18next';

import { NetworkContextName } from '../../constants';
import { styled } from '@material-ui/core';
import { network } from '../../connectors';

const MessageWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '20rem',
});

const Message = styled('h2')({});

export default function Web3ReactManager({
  children,
}: {
  children: JSX.Element;
}) {
  const { t } = useTranslation();
  const { active } = useWeb3React();
  const {
    active: networkActive,
    error: networkError,
    activate: activateNetwork,
  } = useWeb3React(NetworkContextName);

  useEffect(() => {
    if (!networkActive && !networkError && !active) {
      activateNetwork(network);
    }
  }, [networkActive, networkError, activateNetwork, active]);

  // handle delayed loader state
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(true);
    }, 600);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error
  if (!active && networkError) {
    return (
      <MessageWrapper>
        <Message>{t('unknownError')}</Message>
      </MessageWrapper>
    );
  }

  // if neither context is active, spin
  if (!active && !networkActive) {
    return showLoader ? (
      <MessageWrapper>
        <></>
      </MessageWrapper>
    ) : null;
  }

  return children;
}
