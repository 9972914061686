export default {
  // Moment integration
  'Timestamp Date': '{{date, date}}',
  // App Bar
  'Change Language': '언어 변경',
  'Enter an Address, Transaction Hash or Block Number':
    '거래주소 해시값 혹은 블록번호 입력',
  'JSON-RPC API Documentation': 'JSON-RPC API 문서',
  'Expedition Github': 'Expedition Github',
  'Toggle Dark Mode': '블랙모드 변경',
  // Dashboard
  'Block Height': '블록높이',
  'Chain ID': 'Chain ID',
  Syncing: '동기화',
  'Gas Price': 'Gas 비용',
  'Network Hash Rate': '해시파워',
  Peers: '거점',
  'Hash Rate': 'Hash Rate',
  'Transaction Count': 'Transaction Count',
  'Gas Usage': 'Gas Usage',
  Uncles: 'Uncles',
  'Top Miners': 'Top Miners',
  'Top Miners by extraData': 'Top Miners by extraData',
  'Top Miners by Address': 'Top Miners by Address',
  // block list
  'Block Number': '블록번호',
  Hash: '해시',
  Timestamp: '타임 스탬프',
  Transactions: '거래',
  // block view
  Number: '수량',
  ParentHash: 'ParentHash',
  Miner: '마이너',
  Nonce: '목하',
  Difficulty: '난이도',
  'Extra Data': '추가 데이터',
  'State Root': '주 루트',
  'Transaction Root': '거래 루트',
  'Receipts Root': '영수 루트',
  // transaction view
  Block: '블록',
  'Gas Used': 'Gas 소모',
  'Cumulative Gas Used': '누적 Gas소모',
  'Value in Ether': 'Ether값',
  From: '부터',
  To: '으로',
  'Contract Address': '계약주소',
  'Transaction Index': '거래 인덱스',
  'Receipt Status': '수신상태',
  'Receipt Logs': '수신일지',
  // address view
  'No Transactions for this block range.':
    'No Transactions for this block range.',
  Address: '주소',
  Balance: '잔액',
  Code: '코드',
  // configuration menu
  Configuration: '구성',
  Back: '뒤로',
  'Ethereum RPC': 'Ethereum RPC',
  'Service Runner RPC': 'Service Runner RPC',
}
