import { Box, useTheme, Theme } from '@material-ui/core';
import useEthRPCStore from '../stores/useEthRPCStore';
import * as React from 'react';
import getBlocks, { useBlockNumber } from '../helpers';
import MinerStatsTable from '../components/MinerStatsTable';
import StatCharts from '../components/StatCharts';
import getTheme from '../themes/victoryTheme';
import BlockPagination from '../components/BlockPagination';
import { History } from 'history';
import _ from 'lodash';
import { Block as IBlock } from '@etclabscore/ethereum-json-rpc';
import LoadingImage from '../img/loading.gif';

const useState = React.useState;

interface IProps {
  match: {
    params: {
      address: string;
      block: string;
    };
  };
  history: History;
}

export default (props: IProps) => {
  const [erpc] = useEthRPCStore();
  const [blockNumber] = useBlockNumber(erpc);
  const [blocks, setBlocks] = useState<IBlock[]>();
  const theme = useTheme<Theme>();
  const victoryTheme = getTheme(theme);
  const { block } = props.match.params;
  const blockNum = block !== undefined ? parseInt(block, 10) : blockNumber;
  const from = Math.max(blockNum - 959, 0);
  const to = blockNum;

  React.useEffect(() => {
    if (blockNum === undefined || blockNumber === undefined) {
      return;
    }
    if (blockNum > blockNumber) {
      props.history.push(`/stats/${blockNumber}`);
    }
    if (blockNum < 0) {
      props.history.push(`/stats/0`);
    }
  }, [blockNumber, blockNum, props.history]);

  React.useEffect(() => {
    if (!erpc) {
      return;
    }
    getBlocks(from, to, erpc).then((bl) => {
      setBlocks(_.compact(bl));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to, erpc]);

  if (!blocks || blockNumber === undefined || blockNum > blockNumber) {
    return (
      <Box display='flex' justifyContent='center' height='75%'>
        <img alt='Logo' src={LoadingImage} />
      </Box>
    );
  }

  return (
    <div
      style={{
        margin: 'auto',
        marginTop: 0,
        width: '1320px',
        maxWidth: '100vw',
        textAlign: 'center',
        border: '0px',
        padding: 10,
      }}
    >
      <p
        style={{
          textAlign: 'left',
          margin: '20px 0px 20px 0px',
          fontSize: '15pt',
          fontWeight: 'normal',
        }}
      >
        Charts & Stats
      </p>
      <BlockPagination
        from={from}
        to={to}
        disablePrev={blockNum >= blockNumber}
        disableNext={blockNum === 0}
        onPrev={() => {
          const newQuery = blockNum + 960;
          props.history.push(`/stats/${newQuery}`);
        }}
        onNext={() => {
          const newQuery = Math.max(blockNum - 960, 0);
          props.history.push(`/stats/${newQuery}`);
        }}
      ></BlockPagination>
      <StatCharts blocks={blocks} victoryTheme={victoryTheme} />
      <MinerStatsTable blocks={blocks} blockNumber={blockNumber} />
    </div>
  );
};
