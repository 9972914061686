import React from 'react';
import BigNumber from 'bignumber.js';
import { hexToNumber } from '@etclabscore/eserialize';
import { Box, Grid } from '@material-ui/core';
import ChartCard from '../ChartCard';
import { VictoryChart, VictoryLine } from 'victory';
import { useTranslation } from 'react-i18next';
import { hashesToGH } from '../formatters';

const cellStyle = {
  border: '0px',
  fontSize: '10pt',
  padding: '0px',
  margin: '0px',
};

const config = {
  blockTime: 12, // seconds
  blockHistoryLength: 960,
  chartHeight: 200,
  chartWidth: 400,
};

const blockMapGasUsed = (block: any) => {
  return {
    x: hexToNumber(block.number),
    y: new BigNumber(block.gasUsed).dividedBy(1000000),
  };
};

const blockMapUncles = (block: any) => {
  return {
    x: hexToNumber(block.number),
    y: block.uncles.length,
  };
};

const blockMapTransactionCount = (block: any) => {
  return {
    x: hexToNumber(block.number),
    y: block.transactions.length,
  };
};

const blockMapHashRate = (block: any) => {
  return {
    x: hexToNumber(block.number),
    y: hashesToGH(
      new BigNumber(block.difficulty, 16).dividedBy(config.blockTime),
    ),
  };
};

interface IProps {
  blocks: any[];
  victoryTheme?: any;
}

const StatCharts: React.FC<IProps> = ({ blocks, victoryTheme }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        margin: '10px auto 10px auto',
        maxWidth: '1320px',
        textAlign: 'center',
        border: '0px',
      }}
    >
      <Grid container>
        <Grid item style={cellStyle} lg={3} sm={6} xs={12}>
          <Box padding='5px'>
            <ChartCard title={t('Normalized Hash Rate')}>
              <VictoryChart
                height={config.chartHeight}
                width={config.chartWidth}
                theme={victoryTheme as any}
              >
                <VictoryLine data={blocks.map(blockMapHashRate)} />
              </VictoryChart>
            </ChartCard>
          </Box>
        </Grid>

        <Grid item style={cellStyle} lg={3} sm={6} xs={12}>
          <Box padding='5px'>
            <ChartCard title={t('Transaction Count')}>
              <VictoryChart
                height={config.chartHeight}
                width={config.chartWidth}
                theme={victoryTheme as any}
              >
                <VictoryLine data={blocks.map(blockMapTransactionCount)} />
              </VictoryChart>
            </ChartCard>
          </Box>
        </Grid>

        <Grid item style={cellStyle} lg={3} sm={6} xs={12}>
          <Box padding='5px'>
            <ChartCard title={t('Gas Used')}>
              <VictoryChart
                height={config.chartHeight}
                width={config.chartWidth}
                theme={victoryTheme as any}
              >
                <VictoryLine data={blocks.map(blockMapGasUsed)} />
              </VictoryChart>
            </ChartCard>
          </Box>
        </Grid>

        <Grid item style={cellStyle} lg={3} sm={6} xs={12}>
          <Box padding='5px'>
            <ChartCard title={t('Uncle Blocks')}>
              <VictoryChart
                height={config.chartHeight}
                width={config.chartWidth}
                theme={victoryTheme as any}
              >
                <VictoryLine data={blocks.map(blockMapUncles)} />
              </VictoryChart>
            </ChartCard>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default StatCharts;
