import { useCallback, useEffect, useState } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core';
import { Web3ReactContextInterface } from '@web3-react/core/dist/types';
import { NetworkContextName } from '../constants';

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> {
  const context = useWeb3ReactCore<Web3Provider>();
  const contextNetwork = useWeb3ReactCore<Web3Provider>(NetworkContextName);
  return context.active ? context : contextNetwork;
}

const useTotalTx = () => {
  const [txCount, setTxCount] = useState(0);

  const fetchCount = useCallback(() => {
    fetch(new Request('https://intelligent-mahavira.xenwave.com/tx/total'))
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result) {
          const txCount = result;
          if (Number.isInteger(Number(txCount))) setTxCount(Number(txCount));
        }
      })
      .catch(() => null);
  }, []);

  useEffect(() => {
    fetchCount();
    const handler = setInterval(() => {
      fetchCount();
    }, 10000);
    return () => {
      clearInterval(handler);
    };
  }, [fetchCount]);

  return txCount;
};

const useTotalWallets = () => {
  const [totalWallets, setTotalWallets] = useState(0);

  const fetchWallet = useCallback(() => {
    fetch(new Request('https://intelligent-mahavira.xenwave.com/wallets/total'))
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result) {
          const txCount = result;
          if (Number.isInteger(Number(txCount)))
            setTotalWallets(Number(txCount));
        }
      })
      .catch(() => null);
  }, []);

  useEffect(() => {
    fetchWallet();
    const handler = setInterval(() => {
      fetchWallet();
    }, 10000);
    return () => {
      clearInterval(handler);
    };
  }, [fetchWallet]);

  return totalWallets;
};

const useActiveWallets = () => {
  const [activeWallets, setActiveWallets] = useState(0);

  const fetchCount = useCallback(() => {
    fetch(new Request('https://intelligent-mahavira.xenwave.com/wallets/active'))
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result) {
          const txCount = result;
          if (Number.isInteger(Number(txCount)))
            setActiveWallets(Number(txCount));
        }
      })
      .catch(() => null);
  }, []);

  useEffect(() => {
    fetchCount();
    const handler = setInterval(() => {
      fetchCount();
    }, 10000);
    return () => {
      clearInterval(handler);
    };
  }, [fetchCount]);

  return activeWallets;
};

const useTransactions = (address: string) => {
  const [transactions, setTransactions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchTransactions = useCallback(() => {
    fetch(new Request(`https://intelligent-mahavira.xenwave.com/tx/list/${address}`))
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setTransactions(result);
        setLoading(false);
      })
      .catch(() => null);
  }, [address]);

  useEffect(() => {
    fetchTransactions();
    const handler = setInterval(() => {
      fetchTransactions();
    }, 10000);
    return () => {
      clearInterval(handler);
    };
  }, [fetchTransactions]);

  return { loading, transactions };
};

export { useTotalTx, useTotalWallets, useActiveWallets, useTransactions };
