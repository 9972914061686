import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';

interface IProps {
  from: number;
  to: number;
  disableNext?: boolean;
  disablePrev?: boolean;
  onNext?: () => void;
  onPrev?: () => void;
  style?: any;
}

const BlockPagination: React.FC<IProps> = (props) => {
  return (
    <Grid container>
      <Grid container justify='flex-end'>
        <IconButton onClick={props.onPrev} disabled={props.disablePrev}>
          <ArrowBackIos />
        </IconButton>
        <IconButton onClick={props.onNext} disabled={props.disableNext}>
          <ArrowForwardIos />
        </IconButton>
      </Grid>
      <Grid container justify='flex-start' style={{ marginTop: '-25px' }}>
        <p style={{ fontSize: '10' }}>
          Showing {props.to - props.from + 1} Blocks: <b>{String(props.to)}</b>{' '}
          - {props.from}
        </p>
      </Grid>
    </Grid>
  );
};

export default BlockPagination;
